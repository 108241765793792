.bs-header {
    padding-top: rem(24px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    @include mq-below($md) {
        padding: rem(8px) rem(8px) 0;
        margin-bottom: rem(8px);
        justify-content: start;
        flex-direction: row-reverse;
    }
    .left-wrap {
        display: flex;
        align-items: center;
        @include mq-below($md) {
            gap: rem(8px);
            padding-left: rem(8px);
            flex-grow: 1;
            justify-content: space-between;
            flex-direction: row-reverse;
        }
        .icon {
            background: var(--clr-white);
        }
        .logo-wrap {
            padding-right: rem(20px);
            margin-right: rem(20px);
            border-right: 1px solid var(--clr-white);
            img {
                height: rem(32px);
                @include mq-below($md) {
                    height: rem(24px);
                }
            }
            @include mq-below($md) {
                border: none;
                padding-right: rem(0px);
                margin-right: rem(0px);
            }
        }
        .btn {
            &.MuiButtonBase-root {
                line-height: normal;
            }
            &.btn-link {
                color: var(--clr-white);
            }
        }
        .text {
            padding-left: rem(5px);
            @include font-source(rem($fs-body-3), rem($fs-body-3), var(--font-regular), var(--clr-white));
        }
    }
    .right-wrap {
        display: flex;
        align-items: center;
        gap: rem(16px);
        @include mq-below($md) {
            gap: rem(12px);
            padding-right: rem(8px);
            flex-shrink: 0;
        }
        .menu-action {
            background: var(--clr-grey);
            border-radius: rem(4px);
            .icon {
                background: var(--clr-primary);
                width: rem(48px);
                height: rem(48px);
                @include mq-below($md) {
                    width: rem(36px);
                    height: rem(36px);
                }
            }
        }
        .link-wrap {
            width: rem(320px);
            position: fixed;
            left: auto;
            right: 0;
            top: 0;
            bottom: 0;
            flex-direction: column;
            background: var(--clr-grey);
            z-index: 4;
            padding: rem(32px) rem(24px);
            gap: rem(16px);
            // left: -100%;
            transform: translateX(100%);
            transition: 0.3s ease all;
            // padding-right: rem(16px);
            // position: relative;
            // display: flex;
            // gap: rem(48px);
            @include mq-below($md) {
                transform: translateX(-100%);
                right: auto;
                left: 0;
            }
            .head-wrap {
                padding-bottom: rem(16px);
                border-bottom: 1px solid var(--clr-border);
                // display: none;
                display: flex;
                justify-content: space-between;
                @include mq-below($md) {
                    display: flex;
                    justify-content: space-between;
                }
                .btn {
                    &.MuiButtonBase-root {
                        width: auto;
                        padding: 0;
                    }
                }
                .icon {
                    background: var(--clr-base);
                }
                .title {
                    @include font-source(rem($fs-h5), rem($lh-h5), var(--font-medium), var(--clr-base));
                }
            }
            // @include mq-below($md) {
            //     position: fixed;
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     bottom: 0;
            //     flex-direction: column;
            //     background: var(--clr-grey);
            //     z-index: 3;
            //     padding: rem(32px) rem(24px);
            //     gap: rem(16px);
            //     // left: -100%;
            //     transform: translateX(-100%);
            //     transition: 0.3s ease all;
            // }
            &.open {
                transform: translateX(0);
            }
            &::before {
                position: absolute;
                content: "";
                height: rem(32px);
                width: rem(1px);
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background: var(--clr-white);
            }
            .link {
                &.active {
                    .btn {
                        &.MuiButtonBase-root {
                            background: rgba(255, 255, 255, 0.2);
                            font-weight: var(--font-bold);
                            @include mq-below($md) {
                                color: var(--clr-primary);
                            }
                            &::before {
                                width: 100%;
                            }
                        }
                        &.btn-icon {
                            .icon {
                                @include mq-below($md) {
                                    background: var(--clr-primary);
                                }
                            }
                        }
                    }
                }
                .btn {
                    &.MuiButtonBase-root {
                        padding: rem(12px) rem(12px) rem(12px) rem(8px);
                        @include font-source(rem($fs-body-2), rem($lh-body-3), var(--font-regular), var(--clr-white));
                        transition: 0.3s ease all;
                        // @include mq-below($md) {
                        color: var(--clr-base);
                        border-bottom: 1px solid var(--clr-border);
                        width: 100%;
                        // padding: 0 0 rem(16px);
                        justify-content: flex-start;
                        // }
                        &:hover {
                            background: rgba(255, 255, 255, 0.2);
                            &::before {
                                width: 100%;
                            }
                        }
                        &::before {
                            position: absolute;
                            content: "";
                            height: rem(2px);
                            width: 0;
                            background: var(--clr-primary);
                            left: 0;
                            bottom: 0;
                            transition: 0.3s ease all;
                            @include mq-below($sm) {
                                display: none;
                            }
                        }
                    }
                    &.btn-icon {
                        .icon {
                            // background: var(--clr-white);
                            width: rem(24px);
                            height: rem(24px);
                            // @include mq-below($md) {
                            background: var(--clr-primary);
                            // }
                        }
                    }
                }
                &:last-child {
                    .btn {
                        &.MuiButtonBase-root {
                            border: none;
                        }
                    }
                }
            }
        }
        .notification-wrap {
            position: relative;
            .icon {
                display: block;
                background: var(--clr-white);
                @include mq-below($md) {
                    width: rem(24px);
                    height: rem(24px);
                }
            }
            .count {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: rem(16px);
                width: rem(16px);
                height: rem(16px);
                border-radius: 50%;
                background: var(--clr-black);
                @include font-source(rem($fs-body-5), rem($lh-body-5), var(--font-bold), var(--clr-white));
                padding: rem(2px);
                position: absolute;
                top: rem(5px);
                right: 0;
                @include mq-below($md) {
                    width: rem(12px);
                    height: rem(12px);
                    min-width: rem(12px);
                    font-size: rem($fs-body-6);
                    line-height: rem($lh-body-6);
                    top: rem(4px);
                }
            }
        }
        .profile-wrap {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            .menu-dropdown {
                &.active {
                    display: block;
                }
                display: none;
                position: absolute;
                right: 0;
                top: rem(40px);
                box-shadow: 0px 9px 40px 0px rgba(0, 0, 0, 0.1);
                border-radius: rem(14px);
                background: var(--clr-white);
                z-index: 2;
                overflow: hidden;
                @include mq-below($md) {
                    border-radius: rem(6px);
                }
                .menu-item {
                    padding: rem(12px) rem(20px);
                    border-bottom: 1px solid var(--clr-border);
                    @include mq-below($md) {
                        padding: rem(6px) rem(10px);
                    }
                    &:last-child {
                        border: none;
                    }
                    &:hover {
                        background: var(--clr-primary-100);
                    }
                }
                .btn {
                    &.MuiButtonBase-root {
                        @include font-source(rem($fs-body-2), normal, var(--font-regular), var(--clr-black-v1));
                        @include mq-below($md) {
                            font-size: rem($fs-body-3);
                        }
                    }
                    .sub-text {
                        display: block;
                        @include font-source(rem(11px), normal, var(--font-regular), var(--clr-grey-400));
                    }
                    &.btn-icon {
                        gap: rem(10px);
                        @include mq-below($md) {
                            gap: rem(6px);
                        }
                        .icon {
                            display: inline-block;
                            background: var(--clr-grey-400);
                            flex-shrink: 0;
                            @include mq-below($md) {
                                width: rem(12px);
                                height: rem(12px);
                            }
                        }
                    }
                }
            }
            .img-wrap {
                width: rem(32px);
                height: rem(32px);
                border-radius: rem(8px);
                overflow: hidden;
                border: 1px solid var(--clr-white);
                background: var(--clr-white);
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                    background: var(--clr-primary);
                    display: block;
                }
                img {
                    width: 100%;
                }
                @include mq-below($md) {
                    width: rem(28px);
                    height: rem(28px);
                }
            }
            .icon {
                background: var(--clr-white);
            }
        }
    }
    .message-wrap {
        width: 100%;
        margin: rem(16px) 0 rem(24px);
        @include mq-below($md) {
            background: var(--clr-white);
            margin-bottom: 0;
            padding: rem(14px) rem(16px);
        }
        p {
            @include font-source(rem($fs-body-2), normal, var(--font-regular), var(--clr-white));
            margin-bottom: rem(4px);
            @include mq-below($md) {
                margin-bottom: rem(2px);
                color: var(--clr-base);
                font-size: rem($fs-body-4);
            }
            &:last-child {
                margin-bottom: 0;
            }
            strong {
                font-size: rem($fs-body-1);
                font-weight: var(--font-medium);
                @include mq-below($md) {
                    font-size: rem($fs-body-3);
                }
            }
        }
    }
}
