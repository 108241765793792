.bs-support {
    .icon-wrap {
        width: rem(72px);
        height: rem(72px);
        background-color: var(--clr-primary-100);
        border-radius: 50%;
        margin: 0 auto rem(16px);
        @include mq-below($xs) {
            width: rem(64px);
            height: rem(64px);
            margin-block-end: rem(16px);
        }
    }
    .title {
        margin-block-end: 0.8rem;
        font-size: rem($fs-h3);
        line-height: rem($lh-h3);
        color: var(--clr-primary-500);
        font-weight: var(--font-medium);
        letter-spacing: 0.32px;
        @include mq-below($xs) {
            margin-block-end: rem(4px);
            font-size: rem($fs-h4);
            line-height: rem($lh-h4);
            letter-spacing: 0.24px;
        }
    }
    .desc {
        font-size: rem($fs-h6);
        line-height: rem($lh-h6);
        color: var(--clr-primary-500);
        font-weight: var(--font-medium);
        letter-spacing: 0.18px;
        @include mq-below($xs) {
            font-size: rem($fs-body-1);
            line-height: rem($lh-body-1);
            letter-spacing: 0.16px;
        }
    }
    .steps-wrap {
        padding: rem(24px);
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: rem(12px);
        background-color: var(--clr-primary-100);
        border: 1px solid var(--clr-primary);
        width: rem(500px);
        max-width: 100%;
        margin: 0 auto;
        @include mq-below($xs) {
            padding: rem(16px);
        }
    }
    .steps-title {
        padding-block-end: rem(8px);
        margin-block-end: rem(16px);
        border-bottom: 1px solid var(--clr-primary);
        font-size: rem($fs-h6);
        line-height: rem($lh-h6);
        color: var(--clr-black);
        font-weight: var(--font-medium);
        letter-spacing: 0.18px;
        @include mq-below($xs) {
            padding-block-end: rem(12px);
            margin-block-end: rem(12px);
            letter-spacing: 0.16px;
            font-size: rem($fs-body-1);
            line-height: rem($lh-body-1);
        }
    }
    .step-desc {
        font-size: rem($fs-body-2);
        line-height: rem($lh-body-2);
        color: var(--clr-black-v1);
        font-weight: var(--font-regular);
        letter-spacing: 0.14px;
        margin-inline-start: rem(12px);
        .link {
            color: var(--clr-primary);
            text-decoration: underline;
            font-weight: var(--font-medium);
        }
        @include mq-below($xs) {
            font-size: rem($fs-body-3);
            line-height: rem($lh-body-3);
            letter-spacing: 0.12px;
        }
    }
    .step-desc-bold {
        display: block;
        font-weight: var(--font-medium);
        @include mq-below($xs) {
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
            letter-spacing: 0.14px;
        }
    }
    .item {
        display: flex;
        align-items: center;
        padding-block-end: rem(24px);
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            border-left: 1.5px dashed var(--clr-primary);
            left: rem(12px);
            top: 0;
            z-index: 1;
            // @include mq-below($xs) {
            //     bottom: 0.8rem;
            //     height: 2.6rem;
            // }
        }
        &:last-child {
            padding-block-end: 0;
            &::before {
                display: none;
            }
        }
    }
    .step-icon-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(24px);
        height: rem(24px);
        min-width: rem(24px);
        font-size: rem(14px);
        border: 1.5px dashed var(--clr-primary);
        border-radius: rem(4px);
        color: var(--clr-red);
        background-color: var(--clr-primary);
        position: relative;
        z-index: 2;
        .icon {
            background-color: var(--clr-white);
            width: rem(12px);
            height: rem(12px);
        }
    }
}
