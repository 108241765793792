.mod-switch {
    border-radius: rem(8px);
    border: 1px solid var(--clr-grey-200);
    background: var(--clr-white);
    padding: rem(4px);
    display: inline-flex;
    // margin: rem(18px) 0;
    position: relative;
    z-index: 1;
    // width: rem(324px);
    max-width: 100%;
    @include mq-below($sm) {
        margin: 0;
        // margin: rem(16px) 0;
        // width: 100%;
        width: 50%;
        max-width: rem(480px);
    }
    .radio-wrap {
        @include mq-below($sm) {
            flex: 1 1 0;
        }
    }
    .r-label {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-v9));
        padding: rem(6px) rem(8px);
        display: inline-block;
        min-width: rem(147px);
        position: relative;
        text-align: center;
        cursor: pointer;
        border-radius: rem(4px);
        transition: 0.3s ease all;
        z-index: 2;
        @include mq-below($sm) {
            min-width: 100%;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
    input[id="fgli"]:checked + .r-label,
    input[id="partnership"]:checked + .r-label {
        // background: var(--clr-primary-500);
        color: var(--clr-white);
        font-weight: var(--font-medium);
    }
    input[id="fgli"]:checked ~ .switch-active {
        transform: translateX(0%);
    }
    input[id="partnership"]:checked ~ .switch-active {
        transform: translateX(100%);
    }

    .switch-active {
        position: absolute;
        display: flex;
        height: rem(32px);
        width: rem(147px);
        background: var(--clr-primary-500);
        z-index: 1;
        border-radius: 0.25rem;
        transition: 0.25s ease-out;
        @include mq-below($sm) {
            width: 95%;
            // width: 49%;
        }
    }
}
