.bs-card {
    &.red {
        .icon-wrap {
            background: var(--clr-red);
        }
        .mod-box {
            .title {
                background: var(--clr-red-v1);
                border-bottom: 1px solid var(--clr-red-v2);
            }
        }
    }
    &.purple {
        .icon-wrap {
            background: var(--clr-purple) !important;
        }
        .mod-box {
            .title {
                background: var(--clr-purple-v1);
                border-bottom: 1px solid var(--clr-purple-v2);
            }
        }
    }
    &.yellow {
        .icon-wrap {
            background: var(--clr-yellow);
        }
        .mod-box {
            .title {
                background: var(--clr-yellow-v3);
                border-bottom: 1px solid var(--clr-yellow-v4);
            }
        }
    }
    &.light-yellow {
        .icon-wrap {
            background: var(--clr-yellow);
        }
        .mod-box {
            .title {
                background: var(--clr-yellow-v3);
                border-bottom: 1px solid var(--clr-yellow-v4);
            }
        }
    }
    &.voilet {
        .icon-wrap {
            background: var(--clr-purple);
        }
        .mod-box {
            .title {
                background: var(--clr-purple-v1);
                border-bottom: 1px solid var(--clr-purple-v2);
            }
        }
    }
    &.sky-blue {
        .icon-wrap {
            background: var(--clr-sky-blue);
        }
    }
    &.light-green {
        .icon-wrap {
            background: var(--clr-light-green);
        }
        .mod-box {
            .title {
                background: var(--clr-light-green-v1);
                border-bottom: 1px solid var(--clr-light-green-v2);
            }
        }
    }
    &.pink {
        .icon-wrap {
            background: var(--clr-pink);
        }
        .mod-box {
            .title {
                background: var(--clr-red-v1);
                border-bottom: 1px solid var(--clr-red-v2);
            }
        }
    }
    &.typ-box {
        // .head-wrap {
        //     padding-bottom: rem(12px);
        //     @include mq-below($sm) {
        //         padding: rem(24px) rem(12px) rem(12px);
        //     }
        // }
        // .cont-wrap {
        //     padding-top: rem(12px);
        //     @include mq-below($sm) {
        //         padding: rem(16px) rem(12px) rem(16px);
        //     }
        // }
        .title-wrap {
            .title {
                font-size: rem(28px);
                @include mq-below($sm) {
                    font-size: rem(22px);
                }
            }
        }
    }

    background: var(--clr-white);
    border-radius: rem(16px);
    position: relative;
    height: 100%;
    @include mq-below($sm) {
        border-radius: rem(14px);
        height: auto;
    }
    .head-wrap {
        padding: rem(16px);
        border-bottom: 1px solid var(--clr-border);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .icon-wrap {
        width: rem(48px);
        height: rem(48px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--clr-light-blue);
        flex-shrink: 0;
        .icon {
            width: rem(24px);
            height: rem(24px);
        }
    }
    .title-wrap {
        display: flex;
        align-items: center;
        gap: rem(12px);
        .icon {
            background: var(--clr-white);
        }
        .title {
            @include font-source(rem($fs-h4), normal, var(--font-medium), var(--clr-base));
            @include mq-below($sm) {
                font-size: rem($fs-h5);
            }
            span {
                display: block;
                text-transform: capitalize;
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
                @include mq-below($sm) {
                    font-size: rem($fs-body-2);
                }
            }
        }
    }
    .cont-wrap {
        padding: rem(16px);
        .action-wrap {
            margin-top: rem(16px);
            text-align: center;
            .btn {
                &.MuiButtonBase-root {
                    font-size: rem($fs-body-2);
                    line-height: rem($lh-body-3);
                }
            }
        }
        .bs-card {
            .title-wrap {
                .title {
                    font-size: rem($fs-h4);
                    font-weight: var(--font-regular);
                    @include mq-below($sm) {
                        font-size: rem(22px);
                    }
                }
            }
        }
    }
    &.typ-sub-card {
        border: 1px solid var(--clr-border);
        // .head-wrap {
        //     padding: rem(12px) rem(16px) rem(12px);
        //     @include mq-below($sm) {
        //         padding: rem(21px) rem(8px) rem(12px);
        //     }
        // }
        .title-wrap {
            .title {
                @include mq-below($sm) {
                    font-size: rem(22px);
                }
            }
        }
        .cont-wrap {
            // padding: rem(12px) rem(16px) rem(24px);
            // @include mq-below($sm) {
            //     padding: rem(10px) rem(8px) rem(12px);
            // }
            .bs-table {
                overflow: auto;
                table {
                    @include mq-below($sm) {
                        width: auto;
                        min-width: 100%;
                    }
                }
                th,
                td {
                    padding: rem(12px) rem(16px);
                    @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-base));
                    text-align: center;
                    vertical-align: middle;
                    white-space: nowrap;
                    @include mq-below($sm) {
                        padding: rem(12px) rem(8px);
                        min-width: rem(73px);
                    }
                }
                th {
                    font-weight: var(--font-bold);
                    color: var(--clr-grey-500);
                    &:first-child {
                        text-align: left;
                    }
                }
                td {
                    &:first-child {
                        text-align: left;
                        font-weight: var(--font-medium);
                    }
                }
            }
            .action-wrap {
                margin-top: rem(24px);
                .btn {
                    &.btn-default {
                        padding: rem(12px) rem(16px);
                        @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-bold), var(--clr-base));
                        background: var(--clr-grey-v7);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .bs-table {
        &.typ-simple {
            th {
                &:first-child {
                    text-align: left;
                }
            }
        }
    }
    &.typ-equal-height {
        height: 100%;
        .bs-table {
            &.typ-simple {
                td {
                    &:first-child {
                        height: rem(43px);
                        @include mq-below($sm) {
                            height: rem(44px);
                        }
                    }
                }
            }
        }
    }
}
