.bs-search {
    .MuiInputBase-root {
        font-family: var(--font);
        padding: 0 rem(16px) !important;
        border-radius: rem(8px);
        background: var(--clr-white);
        // border: 1px solid var(--clr-border) !important;
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid var(--clr-grey-500) !important;
            }
        }
    }
    .MuiInputBase-input {
        padding: rem(12px) rem(0px) !important;
    }
    .MuiInputAdornment-root {
        cursor: pointer;
        .icon {
            background: var(--clr-grey-500);
        }
    }
}
