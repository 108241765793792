.bs-select {
    .MuiSelect-icon {
        top: auto;
        bottom: rem(6px);
    }
    &.typ-sm {
        .MuiSelect-select {
            padding: rem(4px) rem(12px) rem(4px);
        }
        .MuiInputBase-root {
            font-weight: var(--font-medium);
            color: var(--clr-grey-500);
            border-color: var(--clr-border-v1) !important;
            box-shadow: none;
            overflow: hidden;
            width: rem(101px);
        }
        .MuiSelect-icon {
            top: auto;
            bottom: auto;
        }
    }
    &.typ-normal {
        .MuiSelect-icon {
            bottom: auto;
            top: auto;
        }
        .MuiSelect-select {
            padding: 1rem;
        }
    }
    .form-label {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500) !important);
        transform: none;
        left: rem(16px);
        top: rem(4px);
        font-family: var(--font);
        background: transparent;
    }
    .MuiInputBase-root {
        font-family: var(--font);
        border-radius: rem(8px);
        background: var(--clr-white);
        border: 1px solid var(--clr-border) !important;
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-300));
        overflow: hidden;
        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border: none !important;
            }
        }
    }
    .MuiSelect-select {
        background: var(--clr-white);
        border-radius: rem(8px);
        padding: rem(24px) rem(16px) rem(8px);
    }
    .MuiOutlinedInput-notchedOutline {
        padding: 0;
        top: 0;
        border: none !important;
    }
    legend {
        display: none;
    }
}
