.bs-date-range {
    // display: none;
    max-width: rem(242px);
    @include mq-below($sm) {
        max-width: rem(220px);
    }
    .rs-input {
        @include font-source(rem($fs-body-1), normal, var(--font-regular), var(--clr-grey-500));
        padding: rem(6.4) rem(12px) rem(6.4) rem(12);
        @include mq-below($sm) {
            font-size: rem($fs-body-2);
        }
    }
    .rs-picker-daterange {
        .rs-input-group-addon {
            color: var(--clr-grey-500);
            padding: 0 rem(12px) !important;
        }
    }
    .rs-input-group {
        border: 1px solid var(--clr-border-v1) !important;
        border-radius: rem(8px);
        box-shadow: none !important;
        outline: none !important;
    }
}
.rs-btn-primary {
    background-color: var(--clr-primary) !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: var(--clr-primary) !important;
}
.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba(255, 0, 0, 0.1) !important;
    color: var(--clr-primary) !important;
}
.rs-calendar-table-cell-in-range::before {
    background-color: var(--clr-primary-100-v2) !important;
}
.rs-picker-daterange-panel {
    @include mq-below($sm) {
        min-width: 100% !important;
    }
}
.rs-picker-daterange-calendar-group {
    @include mq-below($sm) {
        flex-direction: column;
        min-width: 100%;
        height: auto;
    }
}
// .rs-picker-popup {
//     position: fixed !important;
//     top: 0 !important;
//     left: 0 !important;
//     right: 0 !important;
//     bottom: 0 !important;
//     width: 100%;
//     height: 100%;
//     z-index: 1050;
//     overflow-y: auto; /* Allow scrolling within the popup */
//     background-color: white;
// }
