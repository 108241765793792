.bs-legend {
    .l-list {
        @include mq-below($sm) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem(8px) rem(12px);
        }
    }
    .l-item {
        display: flex;
        align-items: center;
        margin-bottom: rem(13px);
        @include mq-below($sm) {
            margin-bottom: 0;
            gap: rem(8px);
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .pair {
        min-width: rem(116px);
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        @include mq-below($md) {
            min-width: rem(56px);
            font-size: rem($fs-body-3);
            font-weight: var(--font-medium);
            color: var(--clr-base);
        }
    }
    .value {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        @include mq-below($md) {
            font-size: rem($fs-body-3);
            line-height: rem($lh-body-2);
        }
    }
    .dot {
        width: rem(13px);
        height: rem(13px);
        border-radius: 50%;
        display: inline-block;
        margin-right: rem(8px);
        @include mq-below($md) {
            width: rem(8px);
            height: rem(8px);
            margin-right: rem(4px);
        }
    }
}
