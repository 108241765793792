.btn {
    &.MuiButtonBase-root {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-medium), var(--clr-white));
        text-transform: capitalize;
        background: transparent;
        min-height: auto;
        min-width: auto;
        padding: 0;
        white-space: nowrap;
        &:hover {
            background: transparent;
        }
    }
    &.btn-default {
        padding: rem(16px) rem(32px);
        background: var(--clr-primary);
        border-radius: rem(8px);
        // text-transform: uppercase;
        min-width: 100%;
        gap: rem(4px);
        @include mq-below($sm) {
            padding: rem(12px) rem(32px);
        }
        .icon {
            background: var(--clr-white);
        }
        &.typ-md {
            min-width: rem(234px);
        }
        &.typ-sm {
            padding: rem(8px) rem(16px);
            font-size: rem($fs-body-3);
            line-height: rem($lh-body-3);
            min-width: rem(146px);
        }
        &:hover {
            background: var(--clr-primary);
        }
    }
    &.btn-link {
        color: var(--clr-primary);
        &.typ-underline {
            text-decoration: underline;
        }
    }
    &.btn-icon {
        line-height: normal;
        color: var(--clr-primary);
        display: flex;
        align-items: center;
        gap: rem(8px);
        .icon {
            background: var(--clr-primary);
        }
        &.typ-grey {
            color: var(--clr-grey-500);
            .icon {
                background: var(--clr-grey-500);
            }
        }
    }
    &.btn-search {
        padding: rem(4px);
        border: 1px solid var(--clr-border-v1);
        border-radius: rem(8px);
        &.Mui-disabled {
            background: var(--clr-grey-300) !important;
        }
        &:hover {
            background: var(--clr-primary) !important;
            border-color: var(--clr-primary);
            .icon {
                background: var(--clr-white) !important;
            }
        }
        .icon {
            background: var(--clr-grey-500);
            width: rem(24px);
            height: rem(24px);
        }
    }
    &.genie-btn {
        // display: none;
        position: fixed;
        bottom: rem(16px);
        right: rem(72px);
        width: rem(56px);
        height: rem(56px);
        background: var(--clr-primary-v1) !important;
        z-index: 3;
        border-radius: rem(12px);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
        @include mq-between($lg, $xxxl) {
            right: rem(40px);
        }
        @include mq-above($xxxl) {
            right: rem(82px);
        }
        @include mq-below($md) {
            right: rem(16px);
            top: auto;
            bottom: rem(32px);
        }
    }
    &.btn-rounded {
        width: rem(97px);
        line-height: normal;
        color: var(--clr-black-v2);
        padding: rem(7px);
        border: 1px solid rgba(167, 167, 167, 0.8);
        border-radius: rem(24px);
        &.highlight {
            font-weight: var(--font-semibold);
            border-color: var(--clr-primary-v1);
        }
    }
}
