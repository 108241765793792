.bs-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiBackdrop-root {
        background: rgba(0, 0, 0, 0.8);
    }
    .modal-wrap {
        width: rem(386px);
        padding: rem(24px);
        border-radius: rem(12px);
        background: var(--clr-white);
        text-align: center;
        position: relative;
        margin: rem(32px) rem(24px);
    }
    .icon-wrap {
        width: rem(64px);
        height: rem(64px);
        margin: rem(40px) auto rem(16px);
        img {
            width: 100%;
        }
    }
    .m-title {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-medium), var(--clr-base));
        margin-bottom: rem(8px);
    }
    .m-desc {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
    }
    .action-wrap {
        margin-top: rem(16px);
        .btn {
            &.btn-default {
                padding: rem(12px) rem(24px);
            }
        }
    }
    .close-btn {
        display: block;
        background: var(--clr-grey-500);
        cursor: pointer;
        position: absolute;
        right: rem(16px);
        top: rem(16px);
    }
    .bs-form {
        text-align: left;
        .form-label {
            font-weight: var(--font-medium);
        }
    }
    .main-title {
        @include font-source(rem($fs-h5), rem($lh-h5), var(--font-medium), var(--clr-base));
        margin-bottom: rem(32px);
    }
}
