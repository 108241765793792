.lyt-box {
    display: flex;
    gap: rem(8px);
    @include mq-between($sm, $md) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    @include mq-below($sm) {
        display: grid;
        gap: rem(12px);
        grid-template-columns: repeat(2, 1fr);
    }
    .span2 {
        grid-column: span 2;
    }
    .mod-box {
        flex: 1 1 auto;
        min-width: rem(105px);
        .title {
            padding: rem(8px) rem(16px);
            font-size: rem($fs-body-1);
            line-height: rem($lh-body-1);
            background: var(--clr-grey-v7) !important;
            border-bottom: 1px solid var(--clr-grey-v12) !important;
            @include mq-below($sm) {
                font-size: rem($fs-body-2);
                line-height: rem($lh-body-2);
            }
        }
        .val {
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
        }
        .info-wrap {
            gap: rem(8px);
        }
        .btn {
            &.MuiButtonBase-root {
                line-height: rem($lh-body-2);
            }
        }
        @include mq-below($sm) {
            flex: none;
        }
    }
}
