.mod-stock-compare {
    &.down {
        .text {
            color: var(--clr-error);
        }
        .icon {
            background: var(--clr-error);
        }
    }
    &.medium {
        .text {
            color: var(--clr-card-yellow-v1);
        }
        .icon {
            background: var(--clr-card-yellow-v1);
        }
    }
    display: flex;
    align-items: center;
    gap: rem(4px);
    padding: rem(4px) rem(4px) rem(4px) 0;
    .icon-wrap {
        width: rem(16px);
        flex-shrink: 0;
    }
    .text {
        @include font-source(rem($fs-body-1), normal, var(--font-medium), var(--clr-success));
        white-space: nowrap;
        @include mq-below($sm) {
            font-size: rem($fs-body-3);
        }
    }
}
