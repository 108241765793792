.bs-genie {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--clr-white);
    @include mq-below($sm) {
        padding-bottom: rem(64px);
    }
    .genie-top-wrap {
        flex-shrink: 0;
    }
    .genie-bottom-wrap {
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // z-index: 1;
        // width: 100%;
        flex-shrink: 0;
        .type-wrap {
            padding: rem(24px);
            box-shadow: 0px -10px 20px 0px rgba(178, 178, 178, 0.2);
            background: var(--clr-white);
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: rem(28px);
            width: 100%;
            .btn {
                flex-shrink: 0;
                &.MuiButtonBase-root {
                    font-size: rem(12px);
                    line-height: normal;
                    font-weight: var(--font-regular);
                }
                &.btn-default {
                    padding: rem(8px) rem(12px);
                    width: auto;
                    min-width: auto;
                    border-radius: rem(30px);
                    gap: rem(4px);
                }
            }
            .form-control {
                flex-grow: 1;
                .MuiInputBase-root {
                    @include font-source(rem($fs-body-2), rem($lh-body-1), var(--font-regular), var(--clr-grey-v2));
                }
                .MuiInputBase-input {
                    padding: 0;
                    border: none !important;
                }
                .MuiOutlinedInput-notchedOutline {
                    border: none !important;
                }
            }
        }
    }
    .close-btn {
        position: absolute;
        top: rem(20px);
        right: rem(16px);
        z-index: 1;
        &.white {
            .icon {
                background: var(--clr-white);
            }
        }
        @include mq-below($sm) {
            top: rem(16px);
        }
    }
    .welcome-message {
        .close-btn {
            .icon {
                background: var(--clr-base);
            }
        }
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: rem(48px);
        padding: 0 rem(16px);
        transform: scale(0.96);
        @include mq-below($sm) {
            justify-content: flex-start;
            gap: rem(48px);
            padding: rem(32px) rem(8px) 0;
            transform: scale(0.92);
        }
        .top-wrap {
            text-align: center;
            .logo-wrap {
                width: rem(136px);
                margin: 0 auto rem(10px);
                @include mq-below($sm) {
                    width: rem(90px);
                }
            }
            .title-wrap {
                .title {
                    @include font-source(rem($fs-h4), normal, var(--font-bold), var(--clr-base));
                    opacity: 0.8;
                    @include mq-below($sm) {
                        font-size: rem($fs-h5);
                    }
                }
                .desc {
                    margin-top: rem(8px);
                    @include font-source(rem($fs-h6), normal, var(--font-regular), var(--clr-base));
                    opacity: 0.9;
                    @include mq-below($sm) {
                        font-size: rem($fs-body-1);
                    }
                }
            }
        }
        .body-wrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: rem(16px);
            .limit-block {
                width: rem(108px);
                height: rem(108px);
                position: relative;
                cursor: pointer;
                .img-wrap {
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                    }
                }
                .desc {
                    @include font-source(rem($fs-body-2), normal, var(--font-medium), var(--clr-white));
                    // letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                }
            }
        }
        .bottom-wrap {
            width: 100%;
            padding-bottom: rem(24px);
            @include mq-below($sm) {
                width: auto;
                position: absolute;
                bottom: rem(48px);
                left: rem(16px);
                right: rem(16px);
                padding-bottom: 0;
            }
        }
    }
    .profile-wrap {
        .name-wrap {
            padding: rem(16px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2px solid rgba(#d9d9d9, 0.5);
            @include mq-below($sm) {
                border-width: rem(4px);
            }
            p {
                @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-base));
                margin-bottom: rem(2px);
                @include mq-below($sm) {
                    font-size: rem($fs-body-4);
                    line-height: normal;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                strong {
                    font-size: rem($fs-body-1);
                    line-height: rem($lh-body-1);
                    font-weight: var(--font-medium);
                    @include mq-below($sm) {
                        font-size: rem($fs-body-3);
                    }
                }
            }
            .img-wrap {
                width: rem(57px);
                @include mq-below($sm) {
                    width: rem(41px);
                }
                img {
                    width: 100%;
                }
            }
        }
        .detail-wrap {
            padding: rem(24px) rem(16px) rem(40px);
            @include mq-below($sm) {
                padding: rem(16px) rem(16px) rem(32px);
            }
            .title-wrap {
                margin-bottom: rem(16px);
                .p-title {
                    @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-bold), var(--clr-base));
                    text-transform: uppercase;
                    margin-bottom: rem(8px);
                }
                .p-desc {
                    @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
                }
            }
            .card-wrap {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: (16px);
                .card-block {
                    border-radius: rem(12px);
                    padding: rem(12px) rem(4px);
                    border: 1px solid var(--clr-base);
                    text-align: center;
                    box-shadow: 0px 4px 20px 0px rgba(231, 231, 231, 0.1);

                    &.pink {
                        border-color: var(--clr-pink-v1);
                        background: var(--clr-pink-light);
                        .icon-wrap {
                            border-color: var(--clr-pink-v1);
                            .icon {
                                background: var(--clr-pink-v1);
                            }
                        }
                        .c-pair {
                            color: var(--clr-pink-dark);
                        }
                    }
                    &.yellow {
                        border-color: var(--clr-card-yellow-v1);
                        background: var(--clr-card-yellow-light);
                        .icon-wrap {
                            border-color: var(--clr-card-yellow-v1);
                            .icon {
                                background: var(--clr-card-yellow-v1);
                            }
                        }
                        .c-pair {
                            color: var(--clr-card-yellow-dark);
                        }
                    }
                    &.light-green {
                        border-color: var(--clr-green-v1);
                        background: var(--clr-green-light);
                        .icon-wrap {
                            border-color: var(--clr-green-v1);
                            .icon {
                                background: var(--clr-green-v1);
                            }
                        }
                        .c-pair {
                            color: var(--clr-green-dark);
                        }
                    }
                    &.light-blue {
                        border-color: var(--clr-blue-v1);
                        background: var(--clr-blue-light);
                        .icon-wrap {
                            border-color: var(--clr-blue-v1);
                            .icon {
                                background: var(--clr-blue-v1);
                            }
                        }
                        .c-pair {
                            color: var(--clr-blue-dark);
                        }
                    }
                    &.purple {
                        border-color: var(--clr-purple);
                        background: var(--clr-purple-v3);
                        .icon-wrap {
                            border-color: var(--clr-purple);
                            .icon {
                                background: var(--clr-purple);
                            }
                        }
                        .c-pair {
                            color: var(--clr-purple);
                        }
                    }
                    .icon-wrap {
                        width: rem(40px);
                        height: rem(40px);
                        margin: 0 auto rem(8px);
                        border: 1px solid var(--clr-base);
                        border-radius: 50%;
                        background: var(--clr-white);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .icon {
                            background: var(--clr-base);
                            display: inline-block;
                        }
                    }
                    .c-pair {
                        @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-semibold), var(--clr-base));
                    }
                    .c-value {
                        margin-top: rem(2px);
                        @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-grey-v2));
                    }
                }
            }
        }
        .earn-wrap {
            padding: rem(30px);
            background: var(--clr-primary-100-v2);
            // background: url("../../images/earn-bg.jpg") no-repeat;
            // background-size: cover;
            // background-position: center center;
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: rem(4px);
            position: relative;
            .confetti {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }
            p {
                @include font-source(rem($fs-h6), rem($lh-body-1), var(--font-medium), var(--clr-base));
            }
            .count {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: rem(8px);
                @include font-source(rem($fs-h3), rem($fs-h4), var(--font-bold), var(--clr-base));
                .icon {
                    width: rem(32px);
                    height: rem(32px);
                }
            }
        }
    }
    .chat-screen {
        padding: rem(32px) rem(24px);
        @include mq-below($sm) {
            padding: rem(32px) rem(16px);
        }
        // overflow: auto;
        .c-list {
            .c-item {
                margin-bottom: rem(24px);
                line-height: 0;
                &.align-right {
                    text-align: right;
                }
            }
        }
        .msg-box {
            padding: rem(16px) rem(24px);
            border-radius: 0 rem(24px) rem(24px);
            background: var(--clr-primary-100-v2);
            display: inline-block;
            @include mq-below($sm) {
                padding: rem(12px) rem(16px);
            }
            p {
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
                opacity: 0.8;
            }
            .txt-highlight {
                font-weight: var(--font-medium);
                color: var(--clr-primary-v1);
            }
        }
        .type-msg-box {
            padding: rem(8px) rem(24px);
            border-radius: rem(24px) rem(24px) 0;
            background: linear-gradient(180deg, #fe4b4b 0%, #940404 100%);
            display: inline-block;
            p {
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-medium), var(--clr-white));
            }
        }
        .table-submit-btn {
            padding-left: rem(62px);
            .btn {
                &.MuiButtonBase-root {
                    background: var(--clr-grey-v3);
                    padding: rem(8px) rem(30px);
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    border-top: none;
                }
            }
        }
        .table-wrap {
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-top-right-radius: rem(24px);
            border-bottom-right-radius: rem(24px);
            border-bottom-left-radius: rem(24px);
            display: inline-block;
            overflow: hidden;
            .form-control {
                .MuiInputBase-root {
                    max-width: rem(112px);
                    @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-v2));
                }
                .MuiInputBase-input {
                    padding: 0;
                    border: none !important;
                }
                .MuiOutlinedInput-notchedOutline {
                    border: none !important;
                }
            }
            table {
                width: auto;
                table-layout: fixed;
            }
            th,
            td {
                padding: rem(12px) rem(16px);
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
                min-width: rem(145px);
                &:first-child {
                    border-right: 1px solid rgba(0, 0, 0, 0.05);
                }
            }
            th {
                color: var(--clr-white);
                font-weight: var(--font-medium);
                background: var(--clr-base);
                @include mq-below($sm) {
                    padding: rem(6px) rem(16px);
                    font-size: rem($fs-body-2);
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: var(--clr-grey-v3);
                    }
                    &:last-child {
                        td {
                            &:first-child {
                                border-bottom-left-radius: rem(24px);
                            }
                            &:last-child {
                                border-bottom-right-radius: rem(24px);
                            }
                        }
                    }
                }
            }
            .number {
                font-weight: var(--font-medium);
                color: var(--clr-primary-v1);
            }
        }
        .btn-grp {
            display: flex;
            gap: rem(8px);
        }
        .card-wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: (16px);
            .card-block {
                border-radius: rem(12px);
                padding: rem(12px) rem(4px);
                border: 1px solid var(--clr-border);
                text-align: center;
                background: var(--clr-white);
                box-shadow: 0px 4px 20px 0px rgba(231, 231, 231, 0.1);
                cursor: pointer;
                transition: 0.3s ease all;
                &:hover {
                    border-width: 1px !important;
                }
                &.light-blue {
                    .icon-wrap {
                        background: var(--clr-blue-light);
                        .icon {
                            background: var(--clr-blue-v1);
                        }
                        .value {
                            color: var(--clr-blue-dark);
                        }
                    }
                    &.active,
                    &:hover {
                        background: var(--clr-blue-light);
                        border: 2px solid var(--clr-blue-v1);
                        .icon-wrap {
                            background: var(--clr-white);
                        }
                        .c-pair,
                        .c-value {
                            color: var(--clr-blue-dark);
                        }
                    }
                }
                &.pink {
                    &.active,
                    &:hover {
                        background: var(--clr-pink-light);
                        border: 2px solid var(--clr-pink-v1);
                        .icon-wrap {
                            background: var(--clr-white);
                        }
                        .c-pair,
                        .c-value {
                            color: var(--clr-pink-v1);
                        }
                    }
                    .icon-wrap {
                        background: var(--clr-pink-light);
                        .icon {
                            background: var(--clr-pink-v1);
                        }
                        .value {
                            color: var(--clr-pink-v1);
                        }
                    }
                }
                &.purple {
                    .icon-wrap {
                        background: var(--clr-purple-v3);
                        .icon {
                            background: var(--clr-purple);
                        }
                        .value {
                            color: var(--clr-purple);
                        }
                    }
                    &.active,
                    &:hover {
                        background: var(--clr-purple-v3);
                        border: 2px solid var(--clr-purple);
                        .icon-wrap {
                            background: var(--clr-white);
                        }
                        .c-pair,
                        .c-value {
                            color: var(--clr-purple);
                        }
                    }
                }
                &.light-green {
                    .icon-wrap {
                        background: var(--clr-green-light);
                        .icon {
                            background: var(--clr-green-dark);
                        }
                        .value {
                            color: var(--clr-green-dark);
                        }
                    }
                    &.active,
                    &:hover {
                        background: var(--clr-green-light);
                        border: 2px solid var(--clr-green-dark);
                        .icon-wrap {
                            background: var(--clr-white);
                        }
                        .c-pair,
                        .c-value {
                            color: var(--clr-green-dark);
                        }
                    }
                }
                .icon-wrap {
                    padding: rem(8px);
                    margin: 0 auto rem(8px);
                    border-radius: rem(72px);
                    background: var(--clr-white);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    gap: rem(4px);
                    .icon {
                        background: var(--clr-base);
                        display: inline-block;
                        width: rem(24px);
                        height: rem(24px);
                    }
                    .value {
                        @include font-source(rem($fs-body-2), rem($lh-body-3), 900, var(--clr-base));
                    }
                }
                .c-pair {
                    @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-semibold), var(--clr-grey-v2));
                }
                .c-value {
                    margin-top: rem(2px);
                    @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-grey-v2));
                }
            }
        }
        .form-table {
            border-radius: rem(12px);
            overflow: hidden;
            border: 1px solid var(--clr-border);
            .table-action {
                text-align: center;
                margin-top: rem(16px);
            }
            &.typ-2 {
                .form-table-wrap {
                    th,
                    td {
                        text-align: left;
                        span {
                            display: block;
                            font-size: rem($fs-body-3);
                            color: var(--clr-base);
                            padding-bottom: rem(4px);
                        }
                    }
                    .btn {
                        .icon {
                            background: var(--clr-primary-v1);
                        }
                    }
                    tbody {
                        tr {
                            &:nth-child(even) {
                                background: var(--clr-grey-v3);
                            }
                            &:nth-child(odd) {
                                background: var(--clr-white);
                            }
                        }
                    }
                }

                .table-cont {
                    padding: rem(8px) 0;
                }
            }
            &.typ-3 {
                border: none;
                .table-head {
                    border: none;
                    padding: rem(16px) 0;
                }
                .table-cont {
                    padding: rem(16px) rem(16px) rem(8px);
                    border: 1px solid var(--clr-border);
                    border-radius: rem(16px);
                }
                .form-table-wrap {
                    thead {
                        th {
                            background: var(--clr-primary-v1);
                            color: var(--clr-white);
                            &:first-child {
                                border-top-left-radius: rem(8px);
                                border-bottom-left-radius: rem(8px);
                            }
                            &:last-child {
                                border-top-right-radius: rem(8px);
                                border-bottom-right-radius: rem(8px);
                            }
                        }
                    }
                    th,
                    td {
                        text-align: left;
                        padding: rem(16px);
                    }
                    td {
                        &:first-child {
                            font-weight: var(--font-medium);
                            color: var(--clr-base);
                            padding-left: rem(24);
                        }
                    }
                    tbody {
                        tr {
                            &:nth-child(even) {
                                background: var(--clr-grey-v3);
                            }
                            &:nth-child(odd) {
                                background: var(--clr-white);
                            }
                        }
                    }
                }
            }
            &.light-blue {
                .icon-wrap {
                    background: var(--clr-blue-light);
                    .icon {
                        background: var(--clr-blue-v1);
                    }
                }
            }
            .table-head {
                padding: rem(12px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: rem(12px);
                border-bottom: 1px solid var(--clr-border);
                .btn-wrap {
                    .icon {
                        background: var(--clr-primary-v1);
                        width: rem(24px);
                        height: rem(24px);
                    }
                }
                .title-wrap {
                    display: flex;
                    align-items: center;
                    gap: rem(12px);
                }
                p {
                    @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
                    span {
                        display: block;
                        font-weight: var(--font-medium);
                        color: var(--clr-base);
                    }
                }
                .icon-wrap {
                    width: rem(48px);
                    height: rem(48px);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon {
                        width: rem(24px);
                        height: rem(24px);
                        display: block;
                    }
                }
            }
            .table-cont {
                padding: rem(4px) rem(12px) rem(12px);
            }
            .form-table-wrap {
                table {
                    min-width: 100%;
                    table-layout: fixed;
                }
                th,
                td {
                    text-align: center;
                    vertical-align: middle;
                    padding: rem(16px) rem(8px);
                    @include font-source(rem($fs-body-2), rem($lh-body-3), var(--font-regular), var(--clr-grey-v2));
                }
                th {
                    color: var(--clr-base);
                    font-weight: var(--font-medium);
                }
                tbody {
                    tr {
                        &:nth-child(odd) {
                            background: var(--clr-grey-v3);
                        }
                    }
                }
                .btn {
                    &.MuiButtonBase-root {
                        font-size: rem($fs-body-3);
                        line-height: rem($lh-body-3);
                    }
                }
            }
        }
    }
    .action-wrap {
        padding: rem(24px) rem(16px);
        flex-shrink: 0;
        // position: absolute;
        // bottom: rem(24px);
        // left: rem(16px);
        // right: rem(16px);
    }
    .genie-head-wrap {
        padding: rem(16px) rem(24px);
        background: var(--clr-primary-v1);
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: rem(12px);
        position: relative;
        &::after {
            position: absolute;
            content: "";
            background: url("../../images/reactangle-genie.png") no-repeat;
            background-size: cover;
            background-position: 100% 100%;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
        }
        @include mq-below($sm) {
            border-bottom-right-radius: rem(32px);
            padding: rem(12px) rem(16px);
        }
        .icon {
            background: var(--clr-white);
            display: block;
            position: relative;
            z-index: 1;
        }
        .g-title {
            position: relative;
            z-index: 1;
            @include font-source(rem($fs-body-1), rem($lh-body-3), var(--font-medium), var(--clr-white));
        }
    }
    .genie-cont-wrap {
        flex-grow: 1;
        overflow: auto;
        background: var(--clr-white);
    }
}
