.bs-pinned-table {
    .MuiPaper-root {
        box-shadow: none;
        border-radius: unset;
    }
    .MuiTableRow-root {
        box-shadow: none;
    }
    .MuiTableHead-root {
        tr {
            th {
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
                font-family: var(--font);
                background: var(--clr-grey-v5);
                border-bottom: 1px solid var(--clr-grey-v6);
                vertical-align: middle;
                &:first-child {
                    display: none !important;
                }
                &.mtd-class {
                    background: var(--clr-red-v1);
                }
                &.ytd-class {
                    background: var(--clr-yellow-v3);
                }
                &.mtd-net-class {
                    background: var(--clr-green-light);
                }
            }
        }
    }
    .MuiTableBody-root {
        tr {
            &:nth-child(even) {
                td {
                    background: var(--clr-grey-v3);
                }
            }
            &[data-pinned="true"] {
                td {
                    @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-semibold), var(--clr-base));
                    background: var(--clr-grey-v7);
                }
            }
            td {
                border: none;
                background: var(--clr-white);
                border-bottom: 1px solid var(--clr-grey-v11);
                @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-base));
                &::after {
                    background: none;
                }
                &:first-child {
                    display: none !important;
                }
            }
        }
    }
    .MuiTableContainer-root {
        height: rem(400px);
        &::-webkit-scrollbar {
            height: 10px;
        }
    }
}
