.mod-tag {
    padding: rem(4px) rem(8px);
    border-radius: rem(120px);
    background: var(--clr-success-100);
    display: inline-flex;
    align-items: center;
    gap: rem(4px);
    .value {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-success));
        @include mq-below($sm) {
            font-size: rem($fs-body-1);
        }
    }
    .icon {
        width: rem(16px);
        height: rem(16px);
        background: var(--clr-success);
        flex-shrink: 0;
    }
    &.typ-border {
        border: 1px solid var(--clr-success);
    }
    &.down {
        &.typ-border {
            border: 1px solid var(--clr-primary-v1);
        }
        background: rgba(228, 28, 56, 0.1);
        .value {
            color: var(--clr-primary-v1);
        }
        .icon {
            -moz-transform: scaleY(-1);
            -o-transform: scaleY(-1);
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
            background: var(--clr-primary-v1);
        }
    }
    &.medium {
        &.typ-border {
            border: 1px solid var(--clr-card-yellow-v1);
        }
        background: rgba(255, 180, 10, 0.1);
        .value {
            color: var(--clr-card-yellow-v1);
        }
        .icon {
            background: var(--clr-card-yellow-v1);
        }
    }
    &.dark {
        background: var(--clr-success-600);
        .value {
            font-weight: var(--font-bold);
            color: var(--clr-white);
        }
        .icon {
            background: var(--clr-white);
        }
        &.down {
            background: var(--clr-error);
        }
    }
}
