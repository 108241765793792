.bs-stock-bar {
    @include mq-below($sm) {
        border-radius: rem(16px);
        background: var(--clr-white);
    }
    .b-head {
        display: none;
        @include mq-below($sm) {
            display: flex;
        }
        justify-content: space-between;
        gap: rem(16px);
        align-items: center;
        padding: rem(16px) rem(24px);
        border-bottom: 1px solid var(--clr-grey-v6);
        .b-title {
            @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-black));
        }
        .b-desc {
            @include font-source(rem($fs-body-3), rem($lh-body-1), var(--font-medium), var(--clr-grey-500));
        }
    }
    .icon-wrap {
        .icon {
            background: var(--clr-grey-500);
            width: rem(22px);
            height: rem(22px);
        }
    }
    .b-list {
        display: flex;
        gap: rem(16px);
        overflow-y: auto;
        padding-bottom: rem(20px);
        @include mq-between($sm, $lg) {
            gap: rem(4px);
        }
        @include mq-below($sm) {
            flex-direction: column;
            gap: rem(0);
            padding: rem(16px) rem(0px) rem(24px) rem(24px);
        }
    }
    .b-item {
        min-width: rem(110px);
        flex: 1 1 0;
        cursor: pointer;
        &:first-child {
            .bar {
                margin: 0;
            }
        }
        // &:hover {
        //     .bar-wrap {
        //         background: rgba(244, 147, 58, 0.15);
        //         @include mq-below($sm) {
        //             background: rgba(156, 185, 254, 0.1);
        //         }
        //     }
        //     .bar {
        //         border: 3px solid var(--clr-grey-dark);
        //         z-index: 1;
        //     }
        // }
        &.active {
            .bar-wrap {
                background: rgba(244, 147, 58, 0.15);
                @include mq-below($sm) {
                    background: rgba(156, 185, 254, 0.1);
                }
            }
            .bar {
                border: 3px solid var(--clr-grey-dark);
                z-index: 1;
                @include mq-below($sm) {
                    z-index: unset;
                }
                .indicator {
                    display: block;
                }
            }
            .pair {
                font-weight: var(--font-medium);
                color: var(--clr-yellow-v1);
                text-decoration: underline;
                @include mq-below($sm) {
                    font-weight: var(--font-regular);
                    color: var(--clr-grey-500);
                    text-decoration: none;
                }
                .icon {
                    @include mq-below($sm) {
                        display: inline-block;
                        transform: rotate(90deg);
                        transform-origin: center center;
                    }
                }
            }
            .num {
                color: var(--clr-yellow-v1);
                @include mq-below($sm) {
                    color: var(--clr-base);
                }
            }
        }
    }
    .bar-wrap {
        border-radius: 0 0 rem(12px) rem(12px);
        background: linear-gradient(0deg, #e9e8e8 0.17%, rgba(233, 232, 232, 0) 99.88%);
        height: rem(350px);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: rem(24px);
        @include mq-below($sm) {
            flex-direction: row-reverse;
            border-radius: 0;
            height: auto;
            gap: rem(16px);
            justify-content: flex-start;
            background: transparent;
        }
    }
    .bar {
        // background: var(--clr-yellow);
        border-radius: rem(12px);
        flex-shrink: 0;
        margin-left: rem(-26px);
        position: relative;
        max-height: calc(100% - 150px);
        @include mq-between($sm, $lg) {
            margin-left: rem(-16px);
        }
        @include mq-below($sm) {
            height: auto !important;
            width: 50%;
            margin-left: 0;
            margin-top: rem(-8px);
            max-height: none;
            max-width: calc(100% - 170px);
        }
        .indicator {
            display: none;
            position: absolute;
            left: 50%;
            bottom: rem(-20px);
            width: rem(20px);
            height: rem(20px);
            background: var(--clr-yellow);
            transform: rotate(45deg) translateX(-50%);
            transform-origin: center center;
            border-right: 3px solid var(--clr-grey-dark);
            border-bottom: 3px solid var(--clr-grey-dark);
            @include mq-below($sm) {
                width: rem(10px);
                height: rem(10px);
                left: auto;
                bottom: auto;
                right: rem(-6px);
                top: calc(50% - rem(10px));
                transform: rotate(-45deg) translateX(0);
                transform-origin: right center;
            }
        }
    }
    .info-wrap {
        display: flex;
        flex-direction: column;
        gap: rem(4px);
        justify-content: end;
        align-items: center;
        flex-grow: 1;
        padding: 0 rem(10px);
        @include mq-below($sm) {
            flex-direction: row;
            flex-flow: wrap;
            justify-content: start;
            padding: rem(13px) rem(10px);
            gap: 0;
        }
    }
    .pair {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
        display: flex;
        align-items: center;
        text-align: center;
        gap: rem(5px);
        .icon {
            width: rem(24px);
            height: rem(24px);
            background: var(--clr-grey-500);
            display: none;
            @include mq-below($sm) {
                background: var(--clr-blue);
            }
        }
        @include mq-below($lg) {
            width: 100%;
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
        }
    }
    .num {
        @include font-source(rem($fs-h5), normal, var(--font-medium), var(--clr-base));
        @include mq-below($lg) {
            font-size: rem($fs-body-1);
        }
    }
    .mod-tag {
        @include mq-below($sm) {
            border: none !important;
            background: transparent;
            padding-bottom: 0;
        }
        .icon {
            @include mq-below($sm) {
                display: none;
            }
        }
        .value {
            @include mq-below($lg) {
                font-size: rem($fs-body-3);
                line-height: normal;
            }
        }
    }
}
