.bs-table-accordion {
    min-height: auto;
    padding: 0;
    background: var(--clr-white);
    border-radius: rem(12px) !important;
    border: 1px solid var(--clr-grey-v1);
    box-shadow: none !important;
    overflow: hidden;
    border-bottom: none;
    &.typ-2 {
        .MuiAccordionSummary-expandIconWrapper {
            top: rem(12px);
        }
        .MuiAccordionSummary-content {
            .acc-item {
                text-align: left;
                width: 100% !important;
                align-items: flex-start !important;
                padding: 0;
                &:first-child {
                    flex-direction: column !important;
                    padding: rem(12px) rem(16px);
                }
            }
        }
        .acc-item {
            width: 100%;
            padding: 0;
        }
        .bs-table {
            width: 100%;
        }
        table {
            table-layout: fixed;
            min-width: 100%;
        }
        th,
        td {
            padding: rem(12px) rem(16px);
            @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-base));
            text-align: center;
            &:first-child {
                text-align: left;
            }
        }
        th {
            color: var(--clr-grey-500);
            font-weight: var(--font-medium);
        }
        td {
            &:first-child {
                font-weight: var(--font-medium);
            }
        }
        .bg-grey {
            background: var(--clr-grey-v3) !important;
        }
    }
    &.typ-align-left {
        th,
        td {
            text-align: left;
            width: 50%;
        }
    }
    .MuiAccordionSummary-root {
        padding: 0;
        min-height: auto !important;
    }
    .MuiAccordionSummary-content {
        margin: 0 !important;
        .acc-item {
            &:first-child {
                width: 100%;
                flex-direction: row;
                padding-right: rem(64px);
                align-items: center;
                background: var(--clr-grey-v3);
                padding: rem(8px) rem(12px);
                .key {
                    font-size: rem($fs-body-1);
                    line-height: rem($lh-body-1);
                }
            }
            // &:nth-child(2),
            // &:nth-child(3) {
            //     width: 50%;
            // }
        }
    }
    .MuiAccordionDetails-root {
        padding: 0;
    }
    .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        right: rem(16px);
        top: rem(8px);
    }
    .key {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        // white-space: nowrap;
    }
    .pair {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-medium), var(--clr-base));
        @include mq-below($sm) {
            line-height: rem($lh-body-2);
            font-size: rem($fs-body-2);
        }
    }
    .mod-tag {
        .value {
            line-height: rem($lh-body-3);
            font-size: rem($fs-body-2);
        }
    }
    .acc-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .acc-item {
        padding: rem(12px);
        display: flex;
        flex-direction: column;
        gap: rem(4px);
        border-bottom: 1px solid var(--clr-grey-v11);
        width: 33.33%;
        &.span2 {
            width: 50%;
        }
        &.action-item {
            width: 100%;
            flex-direction: row;
            justify-content: space-evenly;
            .btn {
                &.MuiButtonBase-root {
                    font-size: rem($fs-body-2);
                    line-height: rem($lh-body-3);
                }
            }
        }
    }
}
