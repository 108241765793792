:root {
    --clr-white: #ffffff;
    --clr-black: #000000;
    --clr-black-v1: #404040;
    --clr-black-v2: #464646;
    --clr-primary: #c21b17;
    --clr-primary-v1: #e41c38;
    --clr-primary-v2: #c31c18;
    --clr-primary-v3: #e82b30;
    --clr-primary-v4: #e10303;
    --clr-primary-100: #f8e8e7;
    --clr-primary-500: #9b1612;
    --clr-primary-100-v1: #faeae8;
    --clr-primary-100-v2: #fff0f0;
    --clr-primary-100-v1-dark: #ff9595;
    --clr-base: #333333;
    --clr-grey: #f5f5f7;
    --clr-grey-v1: #e6e9f0;
    --clr-grey-v2: #666;
    --clr-grey-v3: #f9fafc;
    --clr-grey-v4: #bcbfc7;
    --clr-grey-v5: #f7f8fc;
    --clr-grey-v6: #e9eaed;
    --clr-grey-v7: #dbe0f7;
    --clr-grey-v8: #667085;
    --clr-grey-v9: #627180;
    --clr-grey-v10: #ededed;
    --clr-grey-v11: #eff0f3;
    --clr-grey-v12: #bbc2e4;
    --clr-grey-v13: #ccc;
    --clr-grey-200: #dcdcdc;
    --clr-grey-300: #c2c2c2;
    --clr-grey-400: #a0a0a0;
    --clr-grey-500: #707070;
    --clr-grey-dark: #612e00;
    --clr-border: #e9e9e9;
    --clr-border-v1: #e7e7e7;
    --clr-success: #00982b;
    --clr-success-100: #e5f4e9;
    --clr-success-600: #005b1a;
    --clr-error: #aa1b17;
    --clr-yellow-v1: #c66913;
    --clr-yellow-v2: #f4933a;
    --clr-yellow-v3: #fff7f0;
    --clr-yellow-v4: #ffddbe;
    --clr-yellow-light: #ffdbba;
    --clr-yellow-medium: #ffb773;
    --clr-yellow-semi: #f4933a;
    --clr-yellow-dark: #d36500;

    --clr-yellow: #ffa756;
    --clr-blue: #3c76ff;
    --clr-light-blue: #2c96f1;
    --clr-sky-blue: #56aeff;
    --clr-red: #ff5660;
    --clr-purple: #6643b5;
    --clr-pink: #f358a2;
    --clr-light-green: #71c3a8;

    --clr-light-green-v1: #eefbf7;
    --clr-light-green-v2: #c4ded6;

    --clr-purple-v1: #f7f4fd;
    --clr-purple-v2: #d4c7f1;
    --clr-purple-v3: #f7f3ff;

    --clr-red-v1: #fcf5f5;
    --clr-red-v2: #f4d3d5;

    --clr-pink-v1: #fb547d;
    --clr-pink-dark: #cf113f;
    --clr-pink-light: #fff4f7;

    --clr-card-yellow-v1: #ffb40a;
    --clr-card-yellow-dark: #e39e00;
    --clr-card-yellow-light: #fff7e5;

    --clr-green-v1: #00fec2;
    --clr-green-dark: #05a97d;
    --clr-green-light: #eefffb;

    --clr-blue-v1: #60acff;
    --clr-blue-dark: #4d91da;
    --clr-blue-light: #edf5ff;

    --font: "Roboto", sans-serif;

    /* font-weights */
    --font-regular: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
}

/* Font Sizes */
$fs-h1: 48px;
$fs-h2: 40px;
$fs-h3: 32px;
$fs-h4: 24px;
$fs-h5: 20px;
$fs-h6: 18px;
$fs-body-1: 16px;
$fs-body-2: 14px;
$fs-body-3: 12px;
$fs-body-4: 10px;
$fs-body-5: 8px;
$fs-body-6: 6px;

/* line-heights */

$lh-h1: 64px;
$lh-h2: 56px;
$lh-h3: 40px;
$lh-h4: 32px;
$lh-h5: 28px;
$lh-h6: 24px;
$lh-body-1: 24px;
$lh-body-2: 20px;
$lh-body-3: 16px;
$lh-body-4: 12px;
$lh-body-5: 10px;
$lh-body-6: 8px;

/* breakpoints */
$xxs: 375px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xxl: 1400px;
$xxxl: 1600px;
$xxxxl: 1920px;
