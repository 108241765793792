.bs-tab {
    margin-top: rem(16px);
    @include mq-below($sm) {
        margin-top: rem(12px);
    }
    .MuiTabs-indicator {
        display: none;
    }
    .MuiTabs-flexContainer {
        gap: rem(7px);
        overflow: auto;
        @include mq-below($sm) {
            gap: rem(4px);
        }
    }
    .MuiTabs-root {
        padding-left: rem(12px);
        min-height: auto;
        @include mq-below($sm) {
            padding-left: rem(4px);
        }
    }
    .MuiTab-root {
        text-transform: unset;
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-v2));
        padding: rem(12px) rem(24px);
        min-width: auto;
        min-height: auto;
        border-radius: rem(8px) rem(8px) 0 0;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
        background: var(--clr-grey);
        border: 1px solid var(--clr-grey-200);
        border-bottom: none;
        transition: 0.3s ease all;
        @include mq-below($sm) {
            padding: rem(8px);
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
            min-width: rem(56px);
        }
        &.Mui-selected,
        &:hover {
            background: var(--clr-primary-v1);
            color: var(--clr-white);
            border-color: var(--clr-primary-v1);
        }
        &.Mui-selected {
            font-weight: var(--font-medium);
        }
    }
}
