.bs-form {
    .form-group {
        display: flex;
        flex-direction: column;
        gap: rem(8px);
        .MuiFormHelperText-root {
            margin: 0;
            color: var(--clr-error);
        }
    }
    .form-label {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
        @include mq-below($sm) {
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
        }
    }
    .form-control {
        .MuiInputBase-root {
            border-radius: rem(8px);
            background: var(--clr-white);
            border: 1px solid var(--clr-border) !important;
            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid var(--clr-grey-500);
                }
            }
        }
        .MuiInputBase-input {
            font-family: var(--font);
            height: auto;
            @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
            padding: rem(12px) rem(16px);
            @include mq-below($sm) {
                padding: rem(12px) rem(16px);
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }
        &.MuiTextarea-root {
            padding: rem(16px);
            background: transparent;
            border: 1px solid var(--clr-border) !important;
            box-shadow: none;
            @include mq-below($sm) {
                background: var(--clr-white);
            }
            .MuiTextarea-textarea {
                padding: 0;
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
            }
            .MuiTextarea-endDecorator {
                margin: 0;
                justify-content: end;
                @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-400));
            }
        }
        .icon-login-user {
            background: var(--clr-primary);
            width: rem(24px);
            height: rem(24px);
        }
        .icon-password {
            background: var(--clr-primary);
            width: rem(24px);
            height: rem(24px);
        }
    }
    .bs-select {
        &.typ-sm {
            .icon-agent {
                background: var(--clr-primary);
                width: rem(24px);
                height: rem(24px);
            }
            .MuiSelect-select {
                padding: rem(12px) rem(4px);
            }
            .MuiInputBase-root {
                font-weight: var(--font-medium);
                color: var(--clr-grey-500);
                border-color: var(--clr-border-v1) !important;
                box-shadow: none;
                overflow: hidden;
                width: 100%;
            }
            .MuiSelect-icon {
                top: auto;
                bottom: auto;
            }
        }
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--clr-white) inset !important;
    -webkit-text-fill-color: var(--clr-grey-500) !important;
}
