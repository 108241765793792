.mod-profile {
    display: flex;
    gap: 10px;
    align-items: center;
    .img-wrap {
        width: rem(40px);
        height: rem(40px);
        border-radius: rem(4px);
        overflow: hidden;
        flex-shrink: 0;
    }
    .name-wrap {
        p {
            @include font-source(rem($fs-body-2), normal, var(--font-regular), var(--clr-primary));
        }
        .sub-text {
            display: block;
            @include font-source(rem($fs-body-3), normal, var(--font-regular), var(--clr-black-v1));
        }
    }
}
