.icon-download {
    @include svg-icon("../../images/icons/download.svg", rem(16px), rem(16px));
}
.icon-download-arrow {
    @include svg-icon("../../images/icons/download-arrow.svg", rem(32px), rem(32px));
}
.icon-filter {
    @include svg-icon("../../images/icons/filter.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
}
.icon-password-hide {
    @include svg-icon("../../images/icons/password-hide.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
}
.icon-password-show {
    @include svg-icon("../../images/icons/password-show.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
}
.icon-chevron-down {
    @include svg-icon("../../images/icons/chevron-down.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
    transition: 0.3s ease all;
}
.icon-chevron-up {
    @include svg-icon("../../images/icons/chevron-down.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
    transform: rotate(-180deg);
    transform-origin: center center;
}
.icon-chevron-right {
    @include svg-icon("../../images/icons/chevron-right.svg", rem(16px), rem(16px));
    background: var(--clr-base);
}
.icon-prev {
    @include svg-icon("../../images/icons/prev.svg", rem(32px), rem(32px));
    background: var(--clr-grey-500);
}
.icon-next {
    @include svg-icon("../../images/icons/next.svg", rem(32px), rem(32px));
    background: var(--clr-grey-500);
}
.icon-search {
    @include svg-icon("../../images/icons/search.svg", rem(24px), rem(24px));
    background: var(--clr-grey-500);
}
.icon-up-indicator {
    @include svg-icon("../../images/icons/up-indicator.svg", rem(16px), rem(16px));
    background: var(--clr-success);
}
.icon-down-indicator {
    @include svg-icon("../../images/icons/down-indicator.svg", rem(16px), rem(16px));
    background: var(--clr-error);
}
.icon-wallet {
    @include svg-icon("../../images/icons/wallet.svg", rem(24px), rem(24px));
}
.icon-notification {
    @include svg-icon("../../images/icons/notifications.svg", rem(32px), rem(32px));
}
.icon-user {
    @include svg-icon("../../images/icons/user.svg", rem(16px), rem(16px));
}
.icon-key {
    @include svg-icon("../../images/icons/key.svg", rem(16px), rem(16px));
}
.icon-agent {
    @include svg-icon("../../images/icons/agent.svg", rem(16px), rem(16px));
}
.icon-login-user {
    @include svg-icon("../../images/icons/login-user.svg", rem(16px), rem(16px));
}
.icon-password {
    @include svg-icon("../../images/icons/password.svg", rem(16px), rem(16px));
}
.icon-logout {
    @include svg-icon("../../images/icons/logout.svg", rem(16px), rem(16px));
}
.icon-account {
    @include svg-icon("../../images/icons/account.svg", rem(24px), rem(24px));
}
.icon-dashboard {
    @include svg-icon("../../images/icons/dashboard.svg", rem(24px), rem(24px));
}
.icon-description {
    @include svg-icon("../../images/icons/description.svg", rem(24px), rem(24px));
}
.icon-trending {
    @include svg-icon("../../images/icons/trending.svg", rem(32px), rem(32px));
}
.icon-people {
    @include svg-icon("../../images/icons/people.svg", rem(32px), rem(32px));
}
.icon-plus {
    @include svg-icon("../../images/icons/plus.svg", rem(16px), rem(16px));
}
.icon-sync {
    @include svg-icon("../../images/icons/sync.svg", rem(32px), rem(32px));
}
.icon-tools {
    @include svg-icon("../../images/icons/tools.svg", rem(32px), rem(32px));
}
.icon-calendar {
    @include svg-icon("../../images/icons/calendar.svg", rem(32px), rem(32px));
}
.icon-menu {
    @include svg-icon("../../images/icons/menu.svg", rem(40px), rem(40px));
}
.icon-close {
    @include svg-icon("../../images/icons/close.svg", rem(24px), rem(24px));
}
.icon-home {
    @include svg-icon("../../images/icons/home.svg", rem(24px), rem(24px));
}
.icon-up {
    @include svg-icon("../../images/icons/up.svg", rem(6px), rem(4px));
    background: var(--clr-success);
}
.icon-down {
    @include svg-icon("../../images/icons/down.svg", rem(6px), rem(4px));
    background: var(--clr-error);
}
.icon-charger {
    @include svg-icon("../../images/icons/charger.svg", rem(24px), rem(24px));
}
.icon-double-arrow {
    @include svg-icon("../../images/icons/double-arrow.svg", rem(24px), rem(24px));
}
.icon-list {
    @include svg-icon("../../images/icons/list.svg", rem(24px), rem(24px));
}
.icon-copy {
    @include svg-icon("../../images/icons/copy.svg", rem(24px), rem(24px));
}
.icon-chart {
    @include svg-icon("../../images/icons/chart.svg", rem(24px), rem(24px));
}
.icon-medical {
    @include svg-icon("../../images/icons/medical.svg", rem(24px), rem(24px));
}
.icon-work {
    @include svg-icon("../../images/icons/work.svg", rem(24px), rem(24px));
}
.icon-hospital {
    @include svg-icon("../../images/icons/hospital.svg", rem(24px), rem(24px));
}
.icon-membership {
    @include svg-icon("../../images/icons/membership.svg", rem(24px), rem(24px));
}
.icon-brand {
    @include svg-icon("../../images/icons/brand.svg", rem(32px), rem(32px));
    background: var(--clr-white);
}
.icon-arrow-right {
    @include svg-icon("../../images/icons/arrow-right.svg", rem(24px), rem(24px));
}
.icon-bulb {
    @include svg-icon("../../images/icons/bulb.svg", rem(24px), rem(24px));
}
.icon-cake {
    @include svg-icon("../../images/icons/cake.svg", rem(24px), rem(24px));
}
.icon-star {
    @include svg-icon("../../images/icons/star.svg", rem(24px), rem(24px));
}
.icon-man {
    @include svg-icon("../../images/icons/man.svg", rem(24px), rem(24px));
}
.icon-business {
    @include svg-icon("../../images/icons/business.svg", rem(24px), rem(24px));
}
.icon-deliver {
    @include svg-icon("../../images/icons/deliver.svg", rem(16px), rem(16px));
}
.icon-admission {
    @include svg-icon("../../images/icons/admission.svg", rem(24px), rem(24px));
}
.icon-document {
    @include svg-icon("../../images/icons/document.svg", rem(24px), rem(24px));
}
.icon-demography {
    @include svg-icon("../../images/icons/demography.svg", rem(24px), rem(24px));
}
.icon-person {
    @include svg-icon("../../images/icons/person.svg", rem(24px), rem(24px));
}
