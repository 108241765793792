.lyt-dashboard {
    position: relative;
    height: 100vh;
    &::before {
        position: fixed;
        content: "";
        background: linear-gradient(106deg, #e31b37 70.28%, #750000 136.85%);
        height: rem(356px);
        width: 100%;
    }
    .shape1 {
        position: fixed;
        right: rem(56px);
        top: 0;
    }
    .shape2 {
        position: fixed;
        right: rem(56px);
        top: rem(102px);
    }
    &.typ-support {
        .bs-header {
            .message-wrap {
                @include mq-below($sm) {
                    display: none;
                }
            }
        }
        .btn.genie-btn {
            display: none;
        }
    }
    .switch-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-wrap: wrap;
        margin: rem(18px) 0;
        gap: rem(16px);
        @include mq-below($sm) {
            flex-wrap: wrap;
            gap: rem(16px) rem(4px);
        }
        .bs-select {
            margin-left: auto;
        }
        .breadcrumb-wrap {
            width: 80%;
            @include mq-below($sm) {
                order: 3;
                width: 100%;
            }
            .bs-breadcrumb {
                margin-top: 0;
            }
        }
        .bs-date {
            background: var(--clr-white);
            .flatpickr-input,
            .form-control {
                width: rem(115px);
                padding: rem(6.5) rem(30px) rem(6.5) rem(9);
                @include mq-below($sm) {
                    width: rem(32px);
                    padding: rem(4px);
                }
            }
            .icon {
                right: rem(8px);
                @include mq-below($sm) {
                    right: rem(4px);
                }
            }
        }
    }
}
