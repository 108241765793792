html {
    scroll-behavior: smooth;
    @include mq-between($md, $lg) {
        font-size: 13.5px;
    }
    @include mq-between($lg, $xxl) {
        font-size: 15px;
    }
    @include mq-above($xxxl) {
        font-size: 18px;
    }
}

body {
    font-size: rem($fs-body-2);
    line-height: rem($lh-body-2);
    font-family: "Roboto", sans-serif;
    font-weight: var(--font-regular);
    background: var(--clr-grey);
    color: var(--clr-base);
    overflow-x: hidden;
    position: relative;
    padding: 0 !important;
}
.container {
    width: 100%;
    padding: 0 rem(56px);
    // max-width: rem(1440px);
    position: relative;
    margin: 0 auto;
    @include mq-below($md) {
        padding: 0;
    }
    @include mq-between($md, $xxxl) {
        padding: 0 rem(24px);
    }
    @include mq-above($xxxl) {
        max-width: 94%;
        padding: 0 rem(24px);
    }
}
.cm-line-break {
    display: block;
}
.cm-hidden-sm {
    display: block;
    @include mq-below($lg) {
        display: none !important;
    }
}
.cm-hidden-md {
    display: none;
    @include mq-below($md) {
        display: block !important;
    }
}
.cm-hidden-lg {
    display: none;
    @include mq-below($lg) {
        display: block !important;
    }
}
.cm-hidden-tab {
    display: none;
    @include mq-below($sm) {
        display: block !important;
    }
}
.cm-divider {
    border-bottom: 1px solid var(--clr-grey-200);
}
::-webkit-scrollbar {
    width: rem(4px);
    height: rem(4px);
}

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: var(--clr-grey-v10);
    border-radius: rem(20px);
    width: rem(4px);
}

::-webkit-scrollbar-thumb {
    outline: 2px solid var(--clr-grey-v4);
    background: var(--clr-grey-v4);
    border-radius: rem(20px);
    width: rem(4px);
}
.mb-32 {
    margin-bottom: rem(32px);
}
.cm-link-grp {
    padding: rem(40px);
    li {
        list-style: disc;
        margin-bottom: rem(20px);
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-500));
    }
    a {
        color: var(--clr-base);
    }
}
.cm-loader {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--clr-primary);
    border-radius: rem(16px);
    .loading-gif {
        width: rem(60px);
    }
    img {
        margin: 0 auto rem(10px);
    }
}
.cm-align-center {
    text-align: center;
}
.cm-date {
    .rdrDefinedRangesWrapper {
        display: none;
    }
}
.swiper-zoom-container {
    width: 100%;
    height: 100%;
    position: relative;
}
.swiper-zoom-container .zoom-over {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.loader-wrap {
    display: grid;
    place-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: var(--clr-primary-100-v1);
    .img-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
    }
    .loader {
        width: 6rem;
        height: 6rem;
        position: relative;
        border: 5px solid var(--clr-primary-v1);
        border-bottom-color: var(--clr-grey-v1);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1.5s linear infinite;
        @include mq-below($sm) {
            border-width: 2px;
        }
    }
    .text {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
    }
    &.opaque {
        background-color: var(--clr-primary-100-v2);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
