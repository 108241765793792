.bs-data-table {
    border: none !important;
    .MuiDataGrid-footerContainer {
        display: none;
        @include mq-below($sm) {
            // display: block;
        }
    }
    .MuiDataGrid-menuIcon {
        display: none !important;
    }
    .MuiDataGrid-columnSeparator {
        display: none !important;
    }
    .MuiDataGrid-withBorderColor {
        border: none;
    }
    .MuiDataGrid-columnHeaderTitle {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
        font-family: var(--font);
        // text-align: center;
        white-space: wrap;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
        // justify-content: center;
    }
    .MuiDataGrid-columnHeaders {
        @include mq-below($sm) {
            display: none;
        }
    }
    // .MuiDataGrid-columnHeaderTitleContainerContent {
    //     position: relative;
    //     padding: 0 rem(24px);
    // }
    // .MuiDataGrid-columnHeader {
    //     &:not(.MuiDataGrid-columnHeader--sortable) {
    //         .MuiDataGrid-columnHeaderTitleContainer {
    //             justify-content: center;
    //         }
    //     }
    // }
    .MuiDataGrid-columnHeadersInner {
        min-width: 100%;
        & > div {
            min-width: 100%;
        }
        .MuiDataGrid-columnHeader {
            // padding: 0 rem(24px);
            background: var(--clr-grey-v5);
            border-bottom: 1px solid var(--clr-grey-v6);
            // flex: 1 1 0;
            // min-width: auto !important;
            // width: auto !important;
            max-width: none !important;
            position: relative;
            &:first-child {
                // padding: 0 rem(16px) 0 rem(32px);
                padding-left: rem(16px);
                .MuiDataGrid-columnHeaderTitleContainer {
                    justify-content: start;
                }
            }
            // &:nth-child(-n + 2) {
            //     .MuiDataGrid-columnHeaderTitleContainer {
            //         justify-content: start;
            //     }
            // }
        }
    }
    // .MuiDataGrid-iconButtonContainer {
    //     width: auto;
    //     // visibility: visible !important;
    //     position: absolute;
    //     top: 50%;
    //     right: 0;
    //     transform: translateY(-50%);
    // }
    // .MuiDataGrid-columnHeader {
    //     &:not(.MuiDataGrid-columnHeader--sorted) {
    //         .MuiDataGrid-sortIcon {
    //             opacity: 0.5 !important;
    //         }
    //     }
    // }
    .MuiDataGrid-row {
        @include mq-below($sm) {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            width: 100% !important;
            height: auto !important;
            min-height: auto !important;
            max-height: none !important;
            border-radius: rem(12px);
            overflow: hidden;
            border: 1px solid var(--clr-grey-v11) !important;
            margin-bottom: rem(16px);
            background: var(--clr-white) !important;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-child(even) {
            .MuiDataGrid-cell {
                background: var(--clr-grey-v3);
                @include mq-below($sm) {
                    background: var(--clr-white);
                }
            }
        }
        .MuiDataGrid-cell {
            // padding: 0 rem(24px);
            // justify-content: center;
            border: none;
            background: var(--clr-white);
            border-bottom: 1px solid var(--clr-grey-v11);
            @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-base));
            // max-width: none !important;
            &:first-child {
                padding-left: rem(16px);
            }
            @include mq-below($sm) {
                justify-content: flex-start;
                padding: rem(12px) rem(16px);
                min-height: auto !important;
                max-height: none !important;
                min-width: auto !important;
                max-width: none !important;
                @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-medium), var(--clr-base));
            }
            > div {
                position: relative;
                &::before {
                    display: none;
                    @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));

                    @include mq-below($sm) {
                        content: attr(data-th);
                        display: block;
                    }
                }
            }

            &:first-child {
                // padding: 0 rem(16px) 0 rem(32px);
                justify-content: start;
                @include mq-below($sm) {
                    grid-column: span 2;
                    padding: rem(8px) rem(12px);
                    background: var(--clr-grey-v3);
                }
            }
            // &:nth-child(-n + 2) {
            //     justify-content: start;
            // }
        }
    }
    .MuiDataGrid-virtualScrollerContent {
        @include mq-below($sm) {
            width: 100% !important;
            height: auto !important;
        }
    }
    .MuiDataGrid-virtualScrollerRenderZone {
        min-width: 100%;
        @include mq-below($sm) {
            position: relative !important;
        }
    }
    .MuiDataGrid-row {
        min-width: 100%;
    }
    .MuiDataGrid-virtualScroller {
        height: auto;
        &::-webkit-scrollbar {
            width: rem(4px);
            height: 10px;
        }
    }
}
