.bs-breadcrumb {
    margin: rem(24px) 0 rem(16px);
    .b-list {
        display: flex;
        gap: rem(8px);
        overflow: auto;
        @include mq-below($sm) {
            gap: rem(2px);
        }
    }
    .b-item {
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        padding-right: rem(24px);
        position: relative;
        white-space: nowrap;
        @include mq-below($sm) {
            font-size: var($fs-body-4);
            line-height: var($lh-body-4);
            font-weight: var(--font-medium);
            padding-right: rem(20px);
        }
        .link {
            color: inherit;
        }
        &::before {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            @include svg-icon("../../images/icons/chevron-right.svg", rem(12px), rem(12px));
            background: var(--clr-grey-v8);
            @include mq-below($sm) {
                width: rem(14px);
                height: rem(14px);
            }
        }
        &:last-child {
            color: var(--clr-base);
            font-weight: var(--font-medium);
            &::before {
                display: none;
            }
        }
    }
}
