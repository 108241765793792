.bs-menu-dropdown {
    &.typ-toggle {
        .MuiMenuItem-root {
            margin-bottom: rem(2px);
            padding: rem(6px) !important;
            gap: rem(4px);
            .MuiTypography-root {
                @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
            }
        }
    }
    .MuiPaper-root {
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    }
    .MuiList-root {
        padding: rem(8px);
        background: var(--clr-white);
    }
    .MuiMenuItem-root {
        padding: rem(8px) !important;
        margin-bottom: rem(8px);
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        border-radius: rem(4px);
        transition: 0.3s ease all;
        min-height: auto;
        min-width: rem(120px);
        &:last-child {
            margin-bottom: 0;
        }
        &.Mui-selected,
        &:hover {
            font-weight: var(--font-medium);
            color: var(--clr-primary);
            background: var(--clr-primary-100) !important;
        }
    }
    .MuiAutocomplete-listbox {
        padding: 0;
    }
    .MuiAutocomplete-option {
        padding: rem(8px) !important;
        margin-bottom: rem(8px);
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
        border-radius: rem(4px);
        transition: 0.3s ease all;
        &[aria-selected="true"] {
            background: var(--clr-white) !important;
        }
        &.Mui-focused {
            background: var(--clr-white) !important;
        }
        &:hover {
            font-weight: var(--font-medium);
            color: var(--clr-primary);
            background: var(--clr-primary-100) !important;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    .MuiCheckbox-root {
        padding: 0 !important;
        .MuiSvgIcon-root {
            width: rem(16px);
            height: rem(16px);
        }
        &.Mui-checked {
            color: var(--clr-primary) !important;
        }
    }
}
