.lyt-content {
    background: var(--clr-grey);
    border-radius: rem(12px);
    padding: 0 rem(24px) rem(40px);
    min-height: calc(100vh - rem(127px));
    @include mq-below($md) {
        padding: 0 rem(16px) rem(40px);
        border-radius: 0;
        min-height: calc(100vh - rem(88px));
    }
    .table-pagination {
        margin-top: rem(30px);
        display: flex;
        justify-content: flex-end;
        @include mq-below($sm) {
            display: none;
        }
    }
}
