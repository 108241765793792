.bs-pagination {
    .MuiPagination-ul {
        gap: rem(8px);
    }
    .MuiPaginationItem-root {
        margin: 0;
        background: var(--clr-white);
        border: 1px solid var(--clr-border);
        border-radius: rem(8px);
        width: rem(32px);
        height: rem(32px);
        min-height: rem(32px);
        min-width: rem(32px);
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-medium), var(--clr-base));
        &.Mui-selected {
            background: var(--clr-primary) !important;
            border-color: var(--clr-primary);
            color: var(--clr-white);
        }
    }
}
