.bs-table {
    &.typ-simple {
        .icon {
            display: inline-block;
            margin-left: rem(8px);
            @include mq-below($sm) {
                margin-left: rem(2px);
            }
        }
        table {
            table-layout: fixed;
            width: 100%;
            @include mq-between($sm, $md) {
                table-layout: auto;
            }
        }
        th,
        td {
            padding: rem(6px) rem(16px);
            @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-base));
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
        }
        th,
        td:first-child {
            color: var(--clr-black);
            font-weight: var(--font-medium);
        }
        td:first-child {
            text-align: left;
        }
        tbody {
            tr {
                &:nth-child(odd) {
                    td {
                        background: var(--clr-grey-v3);
                        &:first-child {
                            border-radius: rem(4px) 0 0 rem(4px);
                        }
                        &:last-child {
                            border-radius: 0 rem(4px) rem(4px) 0;
                        }
                    }
                }
            }
        }
    }
    &.typ-persistency {
        @include mq-between($sm, $lg) {
            overflow: auto;
        }
        table {
            table-layout: fixed;
            width: auto;
            min-width: 100%;
            @include mq-between($md, $lg) {
                table-layout: auto;
            }
        }
        th,
        td {
            border: 1px solid var(--clr-grey-v6);
            text-align: center;
            vertical-align: middle;
            padding: rem(16px) rem(8px);
            width: rem(106px);
        }
        th {
            background: var(--clr-grey-v5);
            @include font-source(rem($fs-body-1), rem($lh-body-2), var(--font-medium), var(--clr-base));
            @include mq-between($sm, 1300px) {
                font-size: rem($fs-body-2);
            }
        }
        thead {
            tr {
                &:first-child {
                    th {
                        border-top: none;
                        &:first-child {
                            text-align: left;
                            white-space: nowrap;
                            padding-left: rem(32px);
                            border-left: none;
                            @include mq-between($sm, 1300px) {
                                padding-left: rem(16px);
                            }
                        }
                    }
                }
                th {
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        td {
            @include font-source(rem($fs-body-2), rem($lh-body-3), var(--font-regular), var(--clr-base));
            &:first-child {
                text-align: left;
                white-space: nowrap;
                font-weight: var(--font-medium);
                padding-left: rem(32px);
                border-left: none;
                @include mq-between($sm, 1300px) {
                    padding-left: rem(16px);
                }
            }
            &:last-child {
                border-right: none;
            }
        }
        tbody {
            tr {
                &:nth-child(even) {
                    td {
                        background: var(--clr-grey-v3);
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
        tfoot {
            td {
                background: var(--clr-grey-v7);
                border-right: 1px solid var(--clr-white);
                @include font-source(rem($fs-body-1), rem($lh-body-2), var(--font-medium), var(--clr-base));
            }
        }
        .bg-light {
            background: var(--clr-yellow-light) !important;
        }
        .bg-medium {
            background: var(--clr-yellow-medium) !important;
        }
        .bg-semi {
            background: var(--clr-yellow-semi) !important;
        }
        .bg-dark {
            background: var(--clr-yellow-dark) !important;
            color: var(--clr-white) !important;
        }
        .light {
            background: var(--clr-yellow-light) !important;
        }
        .medium {
            background: var(--clr-yellow-medium) !important;
        }
        .semi {
            background: var(--clr-yellow-semi) !important;
        }
        .dark {
            background: var(--clr-yellow-dark) !important;
            color: var(--clr-white) !important;
        }
    }
    &.typ-wip {
        overflow: auto;
        margin: rem(-16px);
        // @include mq-below($sm) {
        // }
        table {
            width: 100%;
            height: 100%;
            table-layout: auto;
            @include mq-below($sm) {
                width: auto;
                min-width: 100%;
            }
        }
        th,
        td {
            padding: rem(16px) rem(16px);
            width: auto;
            white-space: wrap;
            min-width: fit-content;
            @include mq-below($sm) {
                padding: rem(8px) rem(12px);
                font-size: rem($fs-body-3);
                min-width: rem(60px);
            }
        }
        th {
            @include font-source(rem($fs-body-2), rem($lh-body-3), var(--font-medium), var(--clr-base));
            @include mq-below($sm) {
                font-size: rem($fs-body-3);
            }
        }
        thead {
            tr {
                &:first-child {
                    th {
                        white-space: wrap !important;
                        &:first-child {
                            padding-left: rem(16px);
                        }
                    }
                }
            }
        }
        td {
            &:first-child {
                font-weight: var(--font-regular);
                padding-left: rem(16px);
                white-space: wrap;
            }
        }
        tfoot {
            td {
                @include font-source(
                    rem($fs-body-2),
                    rem($lh-body-3),
                    var(--font-semibold) !important,
                    var(--clr-base)
                );
                @include mq-below($sm) {
                    font-size: rem($fs-body-3);
                }
            }
        }
    }
    &.typ-height-equal {
        max-height: rem(432px);
        table {
            table-layout: fixed;
        }
        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }
        tfoot {
            position: sticky;
            bottom: 0;
            z-index: 1;
        }
        // tbody {
        //     height: rem(432px);
        //     @include mq-below($lg) {
        //         height: auto;
        //     }
        // }
    }
    &.typ-collasible {
        overflow: auto;
        margin: rem(-16px);
        th {
            @include font-source(rem($fs-body-2), rem($lh-body-3), var(--font-medium), var(--clr-base));
            background: var(--clr-grey-v7);
            @include mq-below($sm) {
                font-size: rem($fs-body-3);
            }
        }
        thead {
            tr {
                &:first-child {
                    th {
                        white-space: wrap !important;
                        &:first-child {
                            padding-left: rem(16px);
                            min-width: rem(130px);
                            @include mq-below($sm) {
                                min-width: rem(155px);
                            }
                        }
                    }
                }
            }
        }
        td {
            &:first-child {
                padding-left: rem(16px);
                white-space: wrap;
            }
        }
        th,
        td {
            font-weight: var(--font-semibold);
            .icon {
                background: var(--clr-primary);
            }
        }
    }
    .sub-table {
        td {
            background: var(--clr-primary-100-v2) !important;
            padding-top: rem(8px);
            padding-bottom: rem(8px);
            font-weight: var(--font-regular);
            &:first-child {
                font-weight: var(--font-regular);
            }
        }
    }
    &.typ-commo {
        table {
            table-layout: auto;
            // @include mq-below($sm) {
            //     table-layout: fixed;
            // }
        }
        &.typ-abt {
            th,
            td {
                font-size: rem(11.5px) !important;
                @include mq-below($sm) {
                    font-size: rem(10.5px) !important;
                }
            }
        }
        th,
        td {
            padding: rem(6px) rem(6px);
            font-size: rem(13px) !important;
            @include mq-below($sm) {
                font-size: rem(13px) !important;
                padding: rem(6px) rem(4px) !important;
            }
            &:first-child {
                // width: rem(50px);
                width: rem(88px);
                max-width: rem(88px);
                @include mq-below($sm) {
                    max-width: rem(80px);
                }
            }
        }
        .icon {
            margin-left: rem(2px);
        }
    }
}
