.lyt-login {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: rem(0px);
    padding: rem(106px) 0 rem(25px);
    // background: url("../../images/login-bg.png") no-repeat;
    // background-size: cover;
    // background-position: left bottom;
    @include mq-above($xxxl) {
        gap: rem(24px);
    }
    @include mq-below($sm) {
        padding: 0 0 rem(23px);
        gap: rem(0px);
        background: var(--clr-white);
        justify-content: flex-start;
    }

    &::before {
        position: absolute;
        content: "";
        right: 0;
        left: 0;
        top: 0;
        height: rem(127px);
        width: 100%;
        background: url("../../images/login-top-bg.png") no-repeat;
        background-size: cover;
        background-position: right center;
        @include mq-below($sm) {
            display: none;
        }
    }
    &::after {
        position: absolute;
        content: "";
        right: 0;
        left: 0;
        bottom: 0;
        height: 80%;
        width: 100%;
        background: url("../../images/login-bg.png");
        // background: url("../../images/bottom-shapes.svg");
        background-size: cover;
        background-position: left top;
        z-index: -1;
        @include mq-below($sm) {
            display: none;
        }
    }
    .login-wrap {
        border-radius: rem(24px);
        background: var(--clr-white);
        width: rem(440px);
        max-width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 2;
        transform: scale(0.85);
        transform-origin: center center;
        @include mq-above($xxxl) {
            transform: scale(0.92);
        }
        @include mq-below($sm) {
            transform: scale(1);
            border-radius: 0;
        }
    }
    .head-wrap {
        background: url("../../images/login-head-bg.png") no-repeat;
        background-position: center left;
        background-size: cover;
        padding: rem(32px) rem(32px) rem(32px) rem(32px);
        @include mq-below($sm) {
            padding: rem(24px) rem(24px) rem(15px) rem(24px);
        }
    }
    .logo-wrap {
        max-width: rem(99px);
        margin-bottom: rem(16px);
    }
    .title-wrap {
        .text {
            @include font-source(rem($fs-body-2), rem($lh-body-1), var(--font-regular), var(--clr-white));
        }
        .title {
            @include font-source(rem($fs-h4), rem($lh-h4), var(--font-bold), var(--clr-white));
        }
    }
    .form-wrap {
        padding: rem(24px) rem(32px) rem(24px);
        @include mq-below($sm) {
            padding: rem(16px) rem(24px) rem(0px);
        }
    }
    .forgot-wrap {
        text-align: right;
    }
    .bottom-wrap {
        text-align: center;
        p {
            @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
            margin-top: rem(32px);
        }
        .error-message {
            color: var(--clr-primary-500);
            font-weight: var(--font-medium);
        }
        // @include mq-below($sm) {
        //     position: fixed;
        //     bottom: rem(23px);
        //     left: rem(24px);
        //     right: rem(24px);
        // }
    }
    .floating-text {
        font-size: rem(492px);
        line-height: normal;
        font-weight: var(--font-medium);
        text-shadow: 0px 29.31px 19.54px rgba(255, 0, 0, 0.09);
        opacity: 0.05;
        position: absolute;
        top: rem(-180px);
        z-index: 1;
        // background: -webkit-linear-gradient(90deg, #f00 0%, #552c23 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }

    .bottom-logo-wrap {
        max-width: rem(134px);
        margin: 0 auto;
        @include mq-below($sm) {
            // margin: rem(30px) auto rem(20px);
        }
    }
    .bs-form {
        .form-label {
            @include mq-below($sm) {
                display: none;
            }
        }
    }
}
