.bs-menu {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
    margin: 0 rem(-24px);
    position: relative;
    &.is-sticky {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        max-height: rem(64px);
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
        z-index: 3;
        background: var(--clr-white);
    }
    @include mq-below($md) {
        flex-direction: row-reverse;
        margin: 0 rem(-16px);
        background: var(--clr-white);
        border-top: 1px solid var(--clr-grey-v1);
    }
    .btn-wrap {
        display: inline-flex;
        flex-shrink: 0;
        gap: rem(36px);
        padding-right: rem(32px);
        justify-content: end;
        width: rem(340px);
        .count {
            display: none;
            width: rem(12px);
            height: rem(12px);
            background: var(--clr-primary-v2);
            border-radius: 50%;
            @include font-source(rem($fs-body-6), rem($lh-body-6), var(--font-regular), var(--clr-white));
            position: absolute;
            right: rem(4px);
            top: rem(4px);
            @include mq-below($md) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .btn {
            @include mq-below($md) {
                border-radius: rem(8px);
                border: 1px solid var(--clr-border-v1);
                padding: rem(4px);
            }
            .text {
                @include mq-below($md) {
                    display: none;
                }
            }
            .icon {
                width: rem(32px);
                height: rem(32px);
                @include mq-below($md) {
                    background: var(--clr-grey-500);
                    width: rem(24px);
                    height: rem(24px);
                }
            }
        }
        @include mq-below($md) {
            gap: rem(8px);
            position: absolute;
            right: 0;
            top: rem(-46px);
            padding-right: rem(24px);
        }
    }
    .menu-wrap {
        width: calc(100% - rem(350px));
        display: inline-block;
        @include mq-below($md) {
            width: 100%;
        }
        .icon {
            // background: linear-gradient(89.9839deg, #e31b37 40%, #ffe600 100%);
            background: rgb(227, 27, 55);
            background: linear-gradient(30deg, rgba(255, 230, 0, 1) -20%, rgba(227, 27, 55, 1) 70%);
            width: rem(32px);
            height: rem(32px);
            margin-bottom: 0;
            @include mq-below($md) {
                width: rem(20px);
                height: rem(20px);
            }
        }
    }
    .menu-tabs {
        min-height: auto;
        .MuiTabs-flexContainer {
            gap: rem(0px);
        }
        .MuiButtonBase-root {
            padding: rem(16px);
            min-height: auto;
            min-width: auto;
            flex-direction: row;
            text-transform: unset;
            gap: rem(12px);
            @include font-source(rem($fs-body-1), normal, var(--font-regular), var(--clr-grey-500));
            @include mq-below($md) {
                padding: rem(12px);
                gap: rem(4px);
                font-size: rem($fs-body-2);
                line-height: rem($lh-body-2);
            }
            &::before {
                position: absolute;
                content: "";
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                width: 0;
                height: rem(4px);
                background: var(--clr-black);
                transition: 0.3s ease all;
                @include mq-below($md) {
                    height: 2px;
                }
            }
            &.active {
                font-weight: var(--font-semibold);
                &::before {
                    width: 60%;
                }
            }
            &:hover,
            &.active {
                background: rgba(255, 0, 0, 0.1);
                color: var(--clr-primary-v1);
            }
        }
        .MuiTabs-indicator {
            display: none;
        }
        .MuiTabScrollButton-root {
            display: none;
        }
    }
}
