.bs-loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--clr-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-wrap {
        width: rem(225px);
        animation: zoom-in-zoom-out 3s ease infinite;
        @include mq-below($sm) {
            width: rem(194px);
        }
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(0.9, 0.9);
    }
    50% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(0.9, 0.9);
    }
}
