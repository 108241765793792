.bs-drawer {
    &.typ-notification {
        .d-title {
            @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
            text-transform: uppercase;
            @include mq-below($sm) {
                font-size: rem($fs-h5);
                line-height: rem($lh-h5);
                text-transform: capitalize;
            }
        }
        .MuiPaper-root {
            padding: rem(24px) rem(16px);
            background: var(--clr-white);
            @include mq-below($sm) {
                padding: 0;
            }
        }
        .head-wrap {
            padding-bottom: rem(32px);
            margin-bottom: rem(12px);
            border-bottom: 1px solid var(--clr-border);
            @include mq-below($sm) {
                padding: rem(24px) rem(16px);
                margin-bottom: 0;
                border: none;
                background: var(--clr-grey);
            }
        }
        .link {
            &:last-child {
                .mod-notification {
                    border: none;
                }
            }
        }
        .cont-wrap {
            &::-webkit-scrollbar-thumb {
                outline: 2px solid var(--clr-grey-v13);
                background: var(--clr-grey-v13);
            }
        }
    }
    &.typ-genie {
        .MuiPaper-root {
            padding: 0;
        }
    }
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .MuiPaper-root {
        background: var(--clr-grey);
        padding: rem(48px) rem(24px);
        width: rem(386px);
        max-width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include mq-below($sm) {
            width: 100%;
            padding-bottom: rem(64px);
        }
    }
    .close-btn {
        .icon {
            background-color: var(--clr-black);
        }
    }
    .head-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(8px);
        margin-bottom: rem(24px);
        // flex-shrink: 0;
        @include mq-below($sm) {
            margin-bottom: rem(16px);
        }
    }
    .cont-wrap {
        flex-grow: 1;
        overflow-y: auto;
    }
    .foot-wrap {
        // margin-top: rem(24px);
        // flex-shrink: 0;
        // margin-bottom: rem(48px);
        @include mq-below($sm) {
            margin-top: rem(16px);
        }
    }
    .d-title {
        @include font-source(rem($fs-h5), rem($lh-h5), var(--font-medium), var(--clr-base));
    }
}
