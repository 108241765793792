.bs-date {
    position: relative;
    border: 1px solid var(--clr-border-v1);
    border-radius: rem(8px);
    display: none;
    .flatpickr-input,
    .form-control {
        border: none;
        width: rem(105px);
        position: relative;
        z-index: 2;
        background: transparent;
        padding: rem(6.5) rem(44px) rem(6.5) rem(12);
        @include font-source(rem($fs-body-1), normal, var(--font-medium), var(--clr-grey-500));
        @include mq-below($sm) {
            width: rem(32px);
            height: rem(32px);
            padding: rem(4px);
            opacity: 0;
        }
    }
    .icon {
        width: rem(24px);
        height: rem(24px);
        background: var(--clr-grey-500);
        display: inline-block;
        position: absolute;
        right: rem(12px);
        top: 50%;
        transform: translateY(-50%);
        @include mq-below($sm) {
            right: rem(4px);
        }
    }
    &.typ-range {
        @include mq-below($sm) {
            background: var(--clr-white);
        }
        .flatpickr-input,
        .form-control {
            font-weight: var(--font-regular);
            color: var(--clr-grey-300);
            padding-top: rem(26px);
            padding-left: rem(16px);
            width: 100%;
            height: rem(56px);
            opacity: 1;
        }
        .form-label {
            font-size: rem($fs-body-2) !important;
            line-height: rem($lh-body-2) !important;
            font-weight: var(--font-regular) !important;
            color: var(--clr-grey-500);
            position: absolute;
            top: rem(6px);
            left: rem(16px);
        }
        .icon {
            top: auto;
            transform: translate(0);
            bottom: rem(6px);
        }
    }
}
.flatpickr-rContainer {
    width: 100%;
    .flatpickr-monthSelect-months {
        display: grid;
        gap: rem(10px);
        grid-template-columns: repeat(3, 1fr);
    }
    .flatpickr-monthSelect-month {
        padding: rem(10px);
        cursor: pointer;
    }
}
