.lyt-grid-table {
    .bs-table-accordion {
        margin-bottom: rem(16px);
        &:last-child {
            margin-bottom: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem(15px);
        .left-wrap {
            display: flex;
            gap: rem(24px);
            .btn {
                background: var(--clr-white);
                &.btn-search {
                    padding: rem(8px);
                    .icon {
                        width: rem(16px);
                        height: rem(16px);
                        background: var(--clr-base);
                    }
                }
            }
        }
        .right-wrap {
            display: flex;
            align-items: center;
            gap: rem(10px);
            span {
                @include font-source(rem($fs-body-2), normal, var(--font-semibold), var(--clr-base));
            }
        }
        .bs-select {
            &.typ-sm {
                .MuiInputBase-root {
                    width: rem(70px);
                    font-size: rem($fs-body-2);
                }
                .MuiSelect-select {
                    padding: rem(8px) rem(32px) rem(8px) rem(16px);
                }
            }
        }
    }
}
