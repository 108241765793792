.bs-footer {
    background: var(--clr-primary);
    padding: rem(8px) rem(12px);
    @include mq-below($sm) {
        padding: rem(4px) rem(8px);
    }
    p {
        text-align: center;
        @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-white));
        @include mq-below($sm) {
            font-size: rem($fs-body-4);
            line-height: rem($lh-body-3);
        }
    }
}
