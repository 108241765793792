.lyt-support {
    background: var(--clr-grey);
    position: relative;
    padding: rem(40px) 0;
    display: flex;
    // align-items: center;
    justify-content: center;
    z-index: 1;
    min-height: calc(100vh - rem(90px));
    @include mq-above($xxxl) {
        margin: 0 rem(-24px);
    }
    @include mq-between($lg, $xxxl) {
        margin: 0 rem(-24px);
    }
    @include mq-below($md) {
        padding: rem(24px) rem(16px) rem(48px);
        min-height: calc(100vh - rem(102px));
        align-items: flex-start;
        margin: 0;
    }
    .cm-divider {
        margin: 0 rem(-16px);
    }
    .bs-form {
        .form-label {
            font-weight: var(--font-medium);
            font-size: rem($fs-body-1);
            line-height: rem($lh-body-1);
        }
        .action-wrap {
            @include mq-below($sm) {
                text-align: center;
            }
        }
    }
    .action-wrap {
        text-align: center;
        .btn {
            &.MuiButtonBase-root {
                min-width: rem(234px);
            }
        }
    }
    .content-wrap {
        // margin: rem(125px) 0;
        padding: rem(48px) rem(75px);
        border-radius: rem(24px);
        background: var(--clr-white);
        border: 1px solid var(--clr-border);
        width: rem(920px);
        max-width: 100%;
        @include mq-below($sm) {
            margin: 0;
            padding: 0;
            background: transparent;
            border: none;
        }
        .title {
            @include font-source(rem($fs-h4), rem($lh-h6), var(--font-medium), var(--clr-black));
            margin-bottom: rem(40px);
            text-align: center;
            @include mq-below($sm) {
                margin-bottom: rem(24px);
                font-size: rem($fs-h5);
                line-height: rem($lh-h5);
                text-align: left;
            }
        }
        .bs-form {
            .form-group {
                gap: rem(12px);
                @include mq-below($sm) {
                    gap: rem(8px);
                }
            }
            .form-action {
                text-align: center;
                @include mq-below($sm) {
                    position: absolute;
                    left: rem(16px);
                    bottom: rem(48px);
                    right: rem(16px);
                }
                .btn {
                    &.MuiButtonBase-root {
                        min-width: rem(234px);
                        @include mq-below($sm) {
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
    .bs-table {
        &.typ-persistency {
            th,
            td {
                border: none;
                text-align: left;
                padding: rem(20px) rem(16px) rem(20px) rem(32px);
                white-space: nowrap;
                @include mq-below($sm) {
                    padding: rem(16px) !important;
                    font-size: rem($fs-body-2);
                    line-height: rem($lh-body-2);
                }
            }
            th {
                border-top: 1px solid var(--clr-grey-v6) !important;
                border-bottom: 1px solid var(--clr-grey-v6) !important;
            }
            td {
                &:first-child {
                    font-weight: var(--font-regular);
                }
            }
        }
    }
    .bs-panel {
        &.typ-table {
            @include mq-below($sm) {
                background: var(--clr-white);
            }
            .panel-head {
                @include mq-below($sm) {
                    padding: rem(16px);
                }
            }
        }
    }
    // .btn {
    //     &.btn-default {
    //         &.typ-sm {
    //             @include mq-below($sm) {
    //                 padding: rem(12px) rem(24px);
    //                 min-width: rem(218px);
    //                 background: transparent;
    //                 border: 1px solid var(--clr-base);
    //                 color: var(--clr-base);
    //                 font-size: rem($fs-body-1);
    //                 line-height: rem($lh-body-1);
    //                 gap: rem(12px);
    //                 margin-top: rem(8px);
    //             }
    //             .icon {
    //                 @include mq-below($sm) {
    //                     background: var(--clr-base);
    //                     width: rem(24px);
    //                     height: rem(24px);
    //                 }
    //             }
    //         }
    //     }
    // }
}
