.bs-panel {
    &.light-blue {
        .p-title {
            color: var(--clr-light-blue);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.purple {
        .p-title {
            color: var(--clr-purple);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.yellow {
        .p-title {
            color: var(--clr-yellow-v2);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.red {
        .p-title {
            color: var(--clr-red);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.sky-blue {
        .p-title {
            color: var(--clr-sky-blue);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.light-green {
        .p-title {
            color: var(--clr-light-green);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.pink {
        .p-title {
            color: var(--clr-pink);
            @include mq-below($sm) {
                color: var(--clr-base);
            }
        }
    }
    &.typ-table {
        @include mq-below($sm) {
            background: transparent;
        }
        .panel-head {
            @include mq-below($sm) {
                padding: 0 0 rem(16px);
            }
            .right-wrap {
                .cm-hidden-md {
                    @include mq-between($sm, $lg) {
                        display: none !important;
                    }
                }
                .bs-search {
                    @include mq-below($sm) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        transform: translateX(100%);
                        padding: rem(24px) rem(24px) rem(16px);
                        z-index: 3;
                        background: var(--clr-grey-v3);
                        display: none;
                    }
                    // @include mq-below($sm) {
                    //     position: fixed;
                    //     background: rgba(0, 0, 0, 0.8);
                    //     left: 0;
                    //     right: 0;
                    //     top: 0;
                    //     bottom: 0;
                    //     z-index: 3;
                    //     padding: rem(32px) rem(24px);
                    //     display: none;
                    // }
                    &.active {
                        @include mq-below($sm) {
                            display: block;
                            transform: translateX(0%);
                        }
                    }
                }
            }
            .text-msg {
                @include mq-below($sm) {
                    display: none;
                }
            }
        }
        .panel-content {
            padding: 0;
            position: relative;
        }
        // .bs-table {
        //     position: relative;
        //     height: 100%;
        //     tfoot {
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         right: 0;
        //         width: 100%;
        //         min-width: 100%;
        //     }
        // }
    }
    &.typ-bar {
        padding: 0;
        background: transparent;
        .panel-head {
            display: none;
            @include mq-below($sm) {
                display: flex;
                padding: 0;
                margin: rem(0px) 0 rem(16px);
            }
        }
        .panel-content {
            padding: 0;
        }
    }
    &.typ-graph {
        .panel-head {
            // padding: rem(20px) rem(24px);
            border-bottom: 1px solid var(--clr-grey-v6);
            @include mq-below($sm) {
                // padding: rem(20px) rem(16px);
            }
        }
        .panel-content {
            padding: rem(16px);
            // @include mq-below($sm) {
            //     padding: rem(20px) rem(16px) rem(20px);
            // }
        }
        .btn {
            &.MuiButtonBase-root {
                text-transform: unset;
            }
        }
    }
    &.typ-auto {
        height: auto;
    }
    &.typ-border {
        .panel-head {
            border-bottom: 1px solid var(--clr-grey-v6);
        }
    }
    &.typ-transparent {
        .panel-head {
            @include mq-below($sm) {
                border: none;
                padding: 0 0 rem(24px);
            }
        }
        .panel-content {
            @include mq-below($sm) {
                padding: 0;
            }
        }
        @include mq-below($sm) {
            background: transparent;
        }
    }
    border-radius: rem(16px);
    background: var(--clr-white);
    height: 100%;
    display: flex;
    flex-direction: column;
    @include mq-below($sm) {
        height: auto;
    }
    .panel-head {
        padding: rem(16px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        gap: rem(16px);
        .left-wrap {
            flex-grow: 1;
            @include mq-between($md, $lg) {
                max-width: 50%;
            }
        }
        .right-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            gap: rem(16px);
            @include mq-below($sm) {
                gap: rem(8px);
            }
            .btn {
                background-color: var(--clr-white);
            }
        }
        .text-msg {
            margin-left: rem(16px);
            @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-base));
        }
    }
    .panel-content {
        flex-grow: 1;
        padding: rem(16px);
        position: relative;
    }
    .p-title {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
        // max-width: (338px);

        @include mq-below($sm) {
            text-transform: uppercase;
        }
    }
    .bs-search {
        min-width: rem(338px);
        @include mq-between($sm, $lg) {
            min-width: rem(220px);
        }
    }
    .bs-select {
        .MuiInputBase-root {
            font-size: rem(14px);
        }
        &.cm-hidden-sm {
            @include mq-between($sm, $lg) {
                display: block !important;
            }
        }
    }
    .donut-container {
        display: flex;
        min-width: 100%;
        margin: 0 rem(-16px);
        @include mq-below($sm) {
            flex-direction: column;
            margin: 0;
        }
        .d-title {
            text-align: center;
            @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
            margin-bottom: rem(24px);
        }
        .donut-graph-wrap {
            width: rem(185px);
            height: rem(185px);
        }
        .donut-wrap {
            flex: 1 1 0;
            padding: 0 rem(20px);
            border-right: 1px solid var(--clr-grey-v6);
            display: flex;
            align-items: center;
            gap: rem(40px);
            justify-content: center;
            @include mq-below($sm) {
                border-right: none;
                border-bottom: 1px solid var(--clr-grey-v6);
                padding: 0 0 rem(32px);
                margin-bottom: rem(32px);
                flex-direction: column;
                gap: rem(24px);
            }
            &:last-child {
                border: none;
                @include mq-below($sm) {
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }
        .chart-wrap {
            position: relative;
            @include mq-between($md, $lg) {
                max-width: rem(200px);
            }
            @include mq-below($sm) {
                max-width: rem(168px);
            }
            .middle-text {
                text-align: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            .pair {
                @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-regular), var(--clr-grey-500));
                @include mq-below($sm) {
                    font-size: rem($fs-body-3);
                    line-height: rem($lh-body-3);
                }
            }
            .value {
                @include font-source(rem($fs-body-1), normal, var(--font-bold), var(--clr-base));
            }
        }
        .legend-wrap {
            // padding: 0 rem(40px);
            flex-shrink: 0;
            @include mq-below($sm) {
                flex-shrink: unset;
            }
        }
    }
}
