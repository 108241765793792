.mod-box {
    text-align: center;
    border: 1px solid var(--clr-grey-200);
    border-radius: rem(4px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .title {
        padding: rem(8px);
        @include font-source(rem($fs-body-2), rem($lh-body-2), var(--font-medium), var(--clr-base));
        background: var(--clr-grey-v7);
        border-bottom: 1px solid var(--clr-grey-v12);
        white-space: nowrap;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq-below($sm) {
            font-size: rem($fs-body-3);
            line-height: rem($lh-body-3);
            white-space: wrap;
            padding: rem(8px) rem(4px);
        }
    }
    .info-wrap {
        padding: rem(8px);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(8px);
        .icon {
            flex-shrink: 0;
        }
        @include mq-below($sm) {
            padding: rem(8px) rem(2px);
            gap: rem(4px);
        }
    }
    .val {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-base));
        white-space: nowrap;
        @include mq-below($sm) {
            font-size: rem($fs-body-2);
            line-height: rem($lh-body-2);
        }
    }
    .btn {
        &.MuiButtonBase-root {
            font-size: rem($fs-body-2);
            line-height: rem($fs-body-2);
        }
    }
    .mod-tag {
        @include mq-below($sm) {
            gap: rem(0px);
            padding: rem(2px) rem(4px);
        }
        .value {
            font-size: rem($fs-body-3);
            line-height: normal;
            @include mq-below($sm) {
                font-size: rem($fs-body-4);
                line-height: rem($fs-body-4);
            }
        }
        .icon {
            @include mq-below($sm) {
                width: rem(10px);
                height: rem(10px);
            }
        }
    }
}
