.bs-swiper {
    margin: 0 rem(-24px) !important;
    padding: 0 rem(24px) rem(20px) !important;
    @include mq-below($md) {
        margin: 0 rem(-16px) !important;
        padding: 0 rem(8px) rem(24px) !important;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: rem(80px);
        height: rem(80px);
        border-radius: 50%;
        background: var(--clr-primary-100-v1);
        border: 1px solid var(--clr-primary-100-v1-dark);
        padding: 0 rem(12px);
        @include mq-below($sm) {
            display: none;
        }
    }
    .swiper-button-next {
        right: rem(-40px);
        justify-content: start;
        @include mq-between($sm, $lg) {
            right: rem(-32px);
            display: none;
        }
    }
    .swiper-button-prev {
        left: rem(-40px);
        justify-content: end;
        @include mq-between($sm, $lg) {
            left: rem(-32px);
            display: none;
        }
    }
    .swiper-pagination {
        display: none;
        @include mq-below($sm) {
            bottom: 0 !important;
            display: block;
        }
    }
    .swiper-pagination-bullet {
        width: rem(6px);
        height: rem(6px);
        margin: 0 rem(4px);
        background: var(--clr-grey-v4);
        transition: 0.3s ease all;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            width: rem(16px);
            border-radius: rem(10px);
            background: var(--clr-primary);
        }
    }
    // .swiper-slide-active {
    //     .bs-card {
    //         @include mq-below($sm) {
    //             border: 2px solid var(--clr-primary-v3);
    //             border-width: 1px;
    //         }
    //     }
    // }
    .bs-card {
        border: 2px solid transparent;
        transition: 0.3s ease all;
        cursor: pointer;
        @include mq-below($sm) {
            border-width: 1px;
        }
        .cont-wrap {
            padding: rem(12px);
            @include mq-below($sm) {
                padding: rem(8px) rem(4px);
            }
        }
        .indicator {
            display: none;
            position: absolute;
            width: rem(22px);
            height: rem(22px);
            background: var(--clr-white);
            border-right: 2px solid var(--clr-primary-v3);
            border-bottom: 2px solid var(--clr-primary-v3);
            bottom: rem(-12px);
            left: 50%;
            transform: rotate(45deg);
        }
        &:hover,
        &.active {
            border: 2px solid var(--clr-primary-v3);
            @include mq-below($sm) {
                border-width: 1px;
            }
        }
        &.active {
            .indicator {
                display: block;
                @include mq-below($sm) {
                    display: none;
                }
            }
        }
    }
    .bs-table {
        &.typ-simple {
            th {
                @include mq-below($sm) {
                    padding: rem(6px) 0;
                }
                &:first-child {
                    @include mq-below($sm) {
                        width: rem(40px);
                        padding-left: rem(8px);
                    }
                }
            }
            td {
                &:first-child {
                    white-space: wrap;
                    @include mq-below($sm) {
                        padding-left: rem(8px);
                        // width: rem(40px);
                        width: rem(80px);
                    }
                }
                @include mq-below($sm) {
                    padding: rem(6px) rem(0px);
                    font-size: rem(11px);
                    line-height: rem($lh-body-3);
                    // padding: rem(4px) rem(0px);
                }
            }
        }
    }
}
