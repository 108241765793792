.mod-notification {
    padding: rem(16px);
    border-bottom: 1px solid var(--clr-border);
    display: flex;
    justify-content: space-between;
    gap: rem(12px);
    &:hover {
        .n-title {
            color: var(--clr-primary-v1);
        }
        .icon {
            background: var(--clr-primary-v1);
        }
    }
    .info-wrap {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: rem(4px);
    }
    .icon {
        flex-shrink: 0;
        margin-top: rem(4px);
        transition: 0.3s ease all;
    }
    .n-title {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-semibold), var(--clr-base));
        transition: 0.3s ease all;
    }
    .n-desc {
        @include font-source(rem($fs-body-1), rem($lh-body-1), var(--font-regular), var(--clr-grey-v2));
    }
    .n-date {
        @include font-source(rem($fs-body-3), rem($lh-body-3), var(--font-regular), var(--clr-grey-v2));
    }
}
